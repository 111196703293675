import {
  viewRatingDelete,
  packageView,
  contestantWrite,
  emailTemplateView,
  emailTemplateWrite,
  serviceChargeUpdate,
  serviceChargeView,
  videoView,
  reportView,
  contestantView,
  earningView,
  orderView,
  orderUpdate,
  promoCodeView,
  productView,
  contentView,
  packageManage,
  abuseContentView,
  abuseContentDelete,
  categoryView,
  categoryWrite,
  contestantDelete,
  contentWrite,
  promoCodeWrite,
  productWriteAdd,
  subAdminManage,
  viewRatingView,
  videoCensor,
  notificationManage, 
  settingFee,
  referralView,
  referralPaid,
} from './configs/permissions'



import cookie from "react-cookies";

export const permissions = cookie.load('permissions') || []

export const routeWithPermissions = [
  {
    path: '/users', permissions: [contestantView, contestantDelete, contestantWrite],
  },
  {
    path: '/users/get/:id', permissions: [contestantView, contestantDelete, contestantWrite],
  },
  {
    path: '/users/add', permissions: [contestantWrite],
  },
  {
    path: '/admins/add', permissions: [subAdminManage],
  },
  {
    path: "/videos", permissions: [videoView, videoCensor],
  },
  {
    path: "/abusive-video", permissions: [abuseContentView, abuseContentDelete],
  },
  {
    path: "/reports/contestantreport", permissions: [reportView],
  },
  {
    path: "/reports/videoreport", permissions: [reportView],
  },
  {
    path: "/reports/winnerreport", permissions: [reportView],
  },
  {
    path: "/reports/subscriptionreport", permissions: [reportView],
  },
  {
    path: "/reports/packagereport", permissions: [reportView],
  },
  {
    path: "/reports/earningreport", permissions: [reportView],
  },
  {
    path: "/reports/cat-winner", permissions: [reportView],
  },
  {
    path: "/package", permissions: [packageView, packageManage],
  },
  {
    path: "/package/add", permissions: [packageManage],
  },
  {
    path: '/content', permissions: [contentView, contentWrite],
  },
  {
    path: '/content/get/:id', permissions: [contentView, contentWrite],
  },
  {
    path: '/content/edit/:id', permissions: [contentWrite],
  },
  {
    path: "/usernotifications/send", permissions: [notificationManage],
  },
  {
    path: "/templates", permissions: [emailTemplateWrite, emailTemplateView],
  },
  {
    path: "/templates/get/:id", permissions: [emailTemplateWrite, emailTemplateView],
  },
  {
    path: "/templates/edit/:id", permissions: [emailTemplateWrite],
  },
  {
    path: "/category", permissions: [categoryView, categoryWrite],
  },
  {
    path: "/category/add", permissions: [categoryWrite],
  },
  {
    path: "/category/edit/:id", permissions: [categoryWrite],
  },
  {
    path: "/sub-category", permissions: [categoryView, categoryWrite],
  },
  {
    path: "/sub-category/edit/:id", permissions: [categoryWrite],
  },
  {
    path: "/sub-category/sub-category/add", permissions: [categoryWrite],
  },

  {
    path: "/product", permissions: [productView, productWriteAdd],
  }, {
    path: "/product/add", permissions: [productWriteAdd],
  }, {
    path: "/product/edit/:id", permissions: [productWriteAdd],
  }, {
    path: "/product/get/:id", permissions: [productWriteAdd, productView],
  },
  // {
  //   path: "/leader-board", permissions: [productView, productWriteAdd],
  // },
  {
    path: '/service-charge', permissions: [serviceChargeView, serviceChargeUpdate],
  },

  {
    path: "/promocode", permissions: [promoCodeView, promoCodeWrite],
  }, {
    path: "/promocode/add", permissions: [promoCodeWrite],
  }, {
    path: "/promocode/edit/:id", permissions: [promoCodeWrite],
  }, {
    path: "/promocode/get/:id", permissions: [promoCodeWrite, promoCodeView],
  },

  {
    path: "/videos/video-list/:id", permissions: ["video:manage", "video:view"],
  },
  {
    path: "users/edit/:id", permissions: ["contestant:manage"],
  }, {
    path: "/admins/add", permissions: ["admin:manage"],
  }, {
    path: "/admins", permissions: ["admin:view", "admin:manage"],
  }, {
    path: "/admins/edit/:id", permissions: ["admin:manage"],
  }, {
    path: "/admins/get/:id", permissions: ["admin:manage", "admin:view"],
  }, {
    path: "/settings", permissions: ["setting:view", "setting:manage"],
  }, {
    path: "/contactus", permissions: ["contactus:view", "contactus:manage"],
  },
  {
    path: '/service-charge', permissions: ["serviceCharge:view", "serviceCharge:manage"],
  },
  {
    path: '/orders', permissions: [orderView, orderUpdate],
  },
  {
    path: '/orders/get/:id', permissions: [orderView, orderUpdate],
  },
  {
    path: '/earning', permissions: [earningView],
  },
  {
    path: '/review-rating', permissions: [viewRatingView, viewRatingDelete],
  },
  {
    path: '/roles', permissions: [subAdminManage],
  },
  {
    path: '/admin-roles', permissions: [subAdminManage],
  },
  {
    path: '/setting-cancel-fee', permissions: [settingFee],
  },
  {
    path: '/influencer-manager', permissions: [referralView],
  },
  {
    path: '/influencer-price-update', permissions: [referralPaid],
  },
]


export const routerNeedPermissions = routeWithPermissions.map((route) => route.path);
