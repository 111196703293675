import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { isEmpty } from 'lodash';
import cookie from 'react-cookies';
import { Redirect } from 'react-router';

import {  apiBaseUrl } from  '../../../configs/configs';
import Introduction from './Introduction';
import CreateTeam from './CreateTeam';
import ManageTeam from './ManageTeam';
import FAQs from './FAQs';

class HowToPlay extends Component {
  constructor(props) {
    super(props);

    this.state = {

      contentData: {},
      id: ''
        
    };
    
    this.getContent().then((res) => {
      console.log('response', res);
      this.setState({ contentData: res.result, id: res.result.id });
    }).catch(e => console.log(e));
    
  }

  async getContent(){

    const token = cookie.load('token');
    const howtoplayApi = `${apiBaseUrl}/howtoplay`;
    const response = await fetch(howtoplayApi);
    if(response.status === 200){
      const jsonResponse = await response.json();
      return jsonResponse;
    } else if(response.status === 401){

      cookie.remove('token');
      return <Redirect to='/login' />

    } else {
      return new Error();
    }
  }  

  render() {
    const introductionData = (this.state.contentData && this.state.contentData.introduction) || {};
    const createTeamData = (this.state.contentData && this.state.contentData.createTeam) || {};
    const manageTeamData = (this.state.contentData && this.state.contentData.manageTeam) || {};
    const faqsData = (this.state.contentData && this.state.contentData.faqs) || {};

    return (

      <div className="container">
        <Card>
          <CardBody>
            {!isEmpty(introductionData) && <Introduction introductionData={window.atob(introductionData)}/>}
            {!isEmpty(createTeamData) && <CreateTeam createTeamData={window.atob(createTeamData)}/>}
            {!isEmpty(manageTeamData) && <ManageTeam manageTeamData={window.atob(manageTeamData)}/>}
            {!isEmpty(faqsData) && <FAQs faqsData={window.atob(faqsData)}/>}
          </CardBody>
        </Card>
      </div>
      
        
    );
  }
}

export default HowToPlay;
