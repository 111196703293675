import React, { Component } from 'react';
import { Button, Badge, Card, CardBody, Input, Col, Row, Table, DropdownToggle, Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import { isEmpty, remove as __remove, some } from 'lodash';
import { dateTimeFormat } from '../../configs/configs';
import { getLocalDateTime } from '../../helper/helper';
import Alert from '../AlertMessage/Alert';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
class SubscriptionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: this.props.userList || [],
      isRowDeleted: false,
      alertClass: '',
      alertMessage: '',
      alert: {}
    };
  };

  static getDerivedStateFromProps(props, state) {
    if (state.userList !== props.userList && state.isRowDeleted === false) {
      return {
        userList: props.userList
      };
    } else {
      return null;
    }
  }

  render() {
    let userList = this.state.userList;

    return (
      <div>
        {this.state.isRowDeleted && <div><hr /><Alert color={this.state.alertClass}>
          {this.state.alertMessage}
        </Alert></div>}
        <Table responsive hover>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">User Name</th>
              <th scope="col">Subscription ID</th>
              <th scope="col">Expire On</th>
              <th scope="col">Registered On</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(userList) ? userList.map((user, index) =>
            // console.log("moment(user.expired_at)------>",moment(user.expired_at).format("MM/DD/YYYY"))
              <tr key={user.id.toString()}>
                <td>{user.id}</td>
                <td>{user.full_name}</td>
                <td>{user.subscriptionData.product_id}</td>
                <td>{user.subscriptionData.expired_at ? moment(user.subscriptionData.expired_at).format("MM/DD/YYYY") :null}</td>
                <td>{!isEmpty(user.subscriptionData.purchase_at) ? moment(user.subscriptionData.purchase_at).format("MM/DD/YYYY") : null}</td>
                <td>
                  
                  <Link className="btn btn-primary" size="sm"
                        to={{pathname: `/reports/subscriptionreport/${user.id}`, state:{userId: user.id,name:user.full_name}}}>View</Link>
                  
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
        {!isEmpty(this.state.alert.message) && <Alert data={this.state.alert} />}
      </div>
    );
  };
};

export default SubscriptionTable;