import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { isEmpty } from 'lodash';

import {  apiBaseUrl } from  '../../../configs/configs';
import ContactUsView from './ContactUsView';

class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {

      contentData: {},
      id: ''
        
    };
    
    this.getContent().then((res) => {

      this.setState({ contentData: res.result, id: res.result.id });
      
    }).catch(e => console.log(e));
    
  }

  async getContent(){

    const howtoplayApi = `${apiBaseUrl}/contactus`;
    const response = await fetch(howtoplayApi);
    if(response.status === 200){
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      return new Error();
    }
  }  

  render() {
    let contactUsData = (this.state.contentData && this.state.contentData.contactUs) || {};

    if(!isEmpty(contactUsData)) {

      try{

        contactUsData = window.atob(contactUsData);

      } catch(err) {

        console.log('error from catch', err);

      }

    }
    
    return (

      <div className="container">
        <Card>
          <CardBody>
            {!isEmpty(contactUsData) && <ContactUsView contactUsData={contactUsData}/>}
          </CardBody>
        </Card>
      </div>
    
    );
  }
}

export default ContactUs;
