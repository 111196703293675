export const contestantView = "contestant:view";
export const contestantWrite = "contestant:write";
export const contestantDelete = "contestant:delete";

export const videoView = "video:view";
export const videoCensor = "video:censor";

export const abuseContentView = "abuseContent:view";
export const abuseContentDelete = "abuseContent:delete";

export const reportView = "report:view";

export const contentView = "content:view";
export const contentWrite = "content:write";

export const notificationManage = "notification:manage";

export const emailTemplateView = "emailTemplate:view";
export const emailTemplateWrite = "emailTemplate:write";

export const categoryView = "category:view";
export const categoryWrite = "category:write";

export const productView = "product:view";
export const productWriteAdd = "product:write/add";

export const serviceChargeView = "serviceCharge:view";
export const serviceChargeUpdate = "serviceCharge:update";

export const promoCodeView = "promocode:view";
export const promoCodeWrite = "promocode:write";

export const orderView = "order:view";
export const orderUpdate = "order:update";

export const packageView = "package:view";
export const packageManage = "package:manage";

export const earningView = "earning:view";

export const viewRatingView = "viewRating:view";
export const viewRatingDelete = "viewRating:delete";

export const subAdminManage = "subAdmin:manage";

export const settingFee = "cancelFee:setting";

export const referralView = "referral:view";
export const referralPaid = "referral:paid";
