import React from "react";

// import DefaultLayout from "./containers/DefaultLayout";
import Roles from "./views/Roles/Roles";
import AdminRole from "./views/AdminRole";
import {
  permissions,
  routerNeedPermissions,
  routeWithPermissions,
} from "./routerWithPermissions";
import SettingCancelFee from "./views/CancelFee";
import Loadable from "react-loadable";
import SubscriptionList from "./views/Report/SubscriptionList.js";

function Loading() {
  return (
    <div>
      <div className={"text-center"} colSpan="8">
        <i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

const Breadcrumbs = Loadable({
  loader: () => import("./views/Base/Breadcrumbs"),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import("./views/Base/Cards"),
  loading: Loading,
});

const Carousels = Loadable({
  loader: () => import("./views/Base/Carousels"),
  loading: Loading,
});

const Collapses = Loadable({
  loader: () => import("./views/Base/Collapses"),
  loading: Loading,
});

const Dropdowns = Loadable({
  loader: () => import("./views/Base/Dropdowns"),
  loading: Loading,
});

const Forms = Loadable({
  loader: () => import("./views/Base/Forms"),
  loading: Loading,
});

const Jumbotrons = Loadable({
  loader: () => import("./views/Base/Jumbotrons"),
  loading: Loading,
});

const ListGroups = Loadable({
  loader: () => import("./views/Base/ListGroups"),
  loading: Loading,
});

const Navbars = Loadable({
  loader: () => import("./views/Base/Navbars"),
  loading: Loading,
});

const Navs = Loadable({
  loader: () => import("./views/Base/Navs"),
  loading: Loading,
});

const Paginations = Loadable({
  loader: () => import("./views/Base/Paginations"),
  loading: Loading,
});

const Popovers = Loadable({
  loader: () => import("./views/Base/Popovers"),
  loading: Loading,
});

const ProgressBar = Loadable({
  loader: () => import("./views/Base/ProgressBar"),
  loading: Loading,
});

const Switches = Loadable({
  loader: () => import("./views/Base/Switches"),
  loading: Loading,
});

const Tables = Loadable({
  loader: () => import("./views/Base/Tables"),
  loading: Loading,
});

const Tabs = Loadable({
  loader: () => import("./views/Base/Tabs"),
  loading: Loading,
});

const Tooltips = Loadable({
  loader: () => import("./views/Base/Tooltips"),
  loading: Loading,
});

const BrandButtons = Loadable({
  loader: () => import("./views/Buttons/BrandButtons"),
  loading: Loading,
});

const ButtonDropdowns = Loadable({
  loader: () => import("./views/Buttons/ButtonDropdowns"),
  loading: Loading,
});

const ButtonGroups = Loadable({
  loader: () => import("./views/Buttons/ButtonGroups"),
  loading: Loading,
});

const Buttons = Loadable({
  loader: () => import("./views/Buttons/Buttons"),
  loading: Loading,
});

const Charts = Loadable({
  loader: () => import("./views/Charts"),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});

const CoreUIIcons = Loadable({
  loader: () => import("./views/Icons/CoreUIIcons"),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import("./views/Icons/Flags"),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import("./views/Icons/FontAwesome"),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import("./views/Icons/SimpleLineIcons"),
  loading: Loading,
});

const Alerts = Loadable({
  loader: () => import("./views/Notifications/Alerts"),
  loading: Loading,
});

const Badges = Loadable({
  loader: () => import("./views/Notifications/Badges"),
  loading: Loading,
});

const Modals = Loadable({
  loader: () => import("./views/Notifications/Modals"),
  loading: Loading,
});

const Colors = Loadable({
  loader: () => import("./views/Theme/Colors"),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import("./views/Theme/Typography"),
  loading: Loading,
});

const Widgets = Loadable({
  loader: () => import("./views/Widgets/Widgets"),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import("./views/Users/Users"),
  loading: Loading,
});

const UserData = Loadable({
  loader: () => import("./views/Users/UserData"),
  loading: Loading,
});

const UserAdd = Loadable({
  loader: () => import("./views/Users/UserAdd"),
  loading: Loading,
});

const Videos = Loadable({
  loader: () => import("./views/Videos/Videos"),
  loading: Loading,
});

const VideoData = Loadable({
  loader: () => import("./views/Videos/VideoData"),
  loading: Loading,
});

const AdminProfile = Loadable({
  loader: () => import("./views/Admin/Profile"),
  loading: Loading,
});

const AdminEdit = Loadable({
  loader: () => import("./views/Admin/ProfileEdit"),
  loading: Loading,
});

const ForgotPassword = Loadable({
  loader: () => import("./views/Pages/Login/ForgotPassword"),
  loading: Loading,
});

const ResetPassword = Loadable({
  loader: () => import("./views/Pages/Login/ResetPassword"),
  loading: Loading,
});

const Slider = Loadable({
  loader: () => import("./views/Slider/Slider"),
  loading: Loading,
});

const SliderAdd = Loadable({
  loader: () => import("./views/Slider/SliderAdd"),
  loading: Loading,
});

const SliderAddNew = Loadable({
  loader: () => import("./views/Slider/SliderAddNew"),
  loading: Loading,
});

const Admins = Loadable({
  loader: () => import("./views/Admins/Admins"),
  loading: Loading,
});

const AdminData = Loadable({
  loader: () => import("./views/Admins/AdminData"),
  loading: Loading,
});

const AdminAdd = Loadable({
  loader: () => import("./views/Admins/AdminAdd"),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import("./views/Settings/Settings"),
  loading: Loading,
});

const Introduction = Loadable({
  loader: () => import("./views/Howtoplay/Introduction"),
  loading: Loading,
});

const CreateTeam = Loadable({
  loader: () => import("./views/Howtoplay/CreateTeam"),
  loading: Loading,
});

const ManageTeam = Loadable({
  loader: () => import("./views/Howtoplay/ManageTeam"),
  loading: Loading,
});

const FAQs = Loadable({
  loader: () => import("./views/Howtoplay/FAQs"),
  loading: Loading,
});

const AboutUs = Loadable({
  loader: () => import("./views/AboutUs/AboutUsView"),
  loading: Loading,
});

const Condition = Loadable({
  loader: () => import("./views/Condition/ConditionView"),
  loading: Loading,
});
const Privacy = Loadable({
  loader: () => import("./views/Privacy/PrivacyView"),
  loading: Loading,
});

const Legality = Loadable({
  loader: () => import("./views/Legality/LegalityView"),
  loading: Loading,
});

const ContactUs = Loadable({
  loader: () => import("./views/ContactUs/ContactUsView"),
  loading: Loading,
});

const NotificationReceived = Loadable({
  loader: () => import("./views/NotificationData/NotificationReceived"),
  loading: Loading,
});

const NotificationSend = Loadable({
  loader: () => import("./views/NotificationData/NotificationSend"),
  loading: Loading,
});

const Templates = Loadable({
  loader: () => import("./views/Template/EmailTemplate.js"),
  loading: Loading,
});

const TemplatesView = Loadable({
  loader: () => import("./views/Template/EmailTemplateView.js"),
  loading: Loading,
});

const TemplatesEdit = Loadable({
  loader: () => import("./views/Template/EmailTemplateEdit.js"),
  loading: Loading,
});

const Contents = Loadable({
  loader: () => import("./views/Content/Content.js"),
  loading: Loading,
});

const ContentsView = Loadable({
  loader: () => import("./views/Content/ContentView.js"),
  loading: Loading,
});

const ContentsEdit = Loadable({
  loader: () => import("./views/Content/ContentEdit.js"),
  loading: Loading,
});

const Packages = Loadable({
  // loader: () => import("./views/Package/Package.js"),
  loader: () => import("./views/Package/PackageNew.js"),
  loading: Loading,
});

const PackageAdd = Loadable({
  // loader: () => import("./views/Package/PackageAdd"),
  loader: () => import("./views/Package/PackageAddNew.js"),
  loading: Loading,
});

const ContestantReport = Loadable({
  loader: () => import("./views/Report/ContestantReport"),
  loading: Loading,
});

const VideoReport = Loadable({
  loader: () => import("./views/Report/VideoReport"),
  loading: Loading,
});

const WinnerReport = Loadable({
  loader: () => import("./views/Report/WinnerReport"),
  loading: Loading,
});

const CatWinnerReport = Loadable({
  loader: () => import("./views/Report/CatWinnerReport"),
  loading: Loading,
});

const PackageReport = Loadable({
  loader: () => import("./views/Report/PackageReport.js"),
  loading: Loading,
});

const SubscriptionReport = Loadable({
  loader: () => import("./views/Report/SubscriptionReport.js"),
  loading: Loading,
});

const EarningReport = Loadable({
  loader: () => import("./views/Report/EarningReport"),
  loading: Loading,
});

//E-commerce Start

const Category = Loadable({
  loader: () => import("./views/Category/Category.js"),
  loading: Loading,
});

const CategoryAdd = Loadable({
  loader: () => import("./views/Category/CategoryAdd"),
  loading: Loading,
});

const SubCategory = Loadable({
  loader: () => import("./views/SubCategory/SubCategory.js"),
  loading: Loading,
});

const SubCategoryAdd = Loadable({
  loader: () => import("./views/SubCategory/SubCategoryAdd"),
  loading: Loading,
});

const Product = Loadable({
  loader: () => import("./views/Product/Product.js"),
  loading: Loading,
});

const ProductAdd = Loadable({
  loader: () => import("./views/Product/ProductAdd"),
  loading: Loading,
});

const ProductData = Loadable({
  loader: () => import("./views/Product/ProductData"),
  loading: Loading,
});

const Promocode = Loadable({
  loader: () => import("./views/Promocode/Promocode.js"),
  loading: Loading,
});

const PromocodeAdd = Loadable({
  loader: () => import("./views/Promocode/PromocodeAdd"),
  loading: Loading,
});

const PromocodeData = Loadable({
  loader: () => import("./views/Promocode/PromocodeData"),
  loading: Loading,
});

const ServiceCharges = Loadable({
  loader: () => import("./views/ServiceCharges/ServiceCharges"),
  loading: Loading,
});

const Orders = Loadable({
  loader: () => import("./views/Orders/Orders"),
  loading: Loading,
});

const OrderData = Loadable({
  loader: () => import("./views/Orders/OrderData"),
  loading: Loading,
});

const Referrals = Loadable({
  loader: () => import("./views/Referrals/referrals"),
  loading: Loading,
});
const ReferralPriceEdit = Loadable({
  loader: () => import("./views/Referrals/referralPriceEdit"),
  loading: Loading,
});


const Earning = Loadable({
  loader: () => import("./views/Earning/Earning.js"),
  loading: Loading,
});

const ReviewRating = Loadable({
  loader: () => import("./views/ReviewRating/ReviewRating.js"),
  loading: Loading,
});

const Leaderboard = Loadable({
  loader: () => import("./views/LeaderBoard/LeaderBoard.js"),
  loading: Loading,
});
const LeaderEdit = Loadable({
  loader: () => import("./views/LeaderBoard/LeaderEdit.js"),
  loading: Loading,
});



//E-commerce End

// abuse video

const AbuseVideos = Loadable({
  loader: () => import("./views/AbuseVideos/Index"),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  // { path: "/", exact: true, name: "Home", component: DefaultLayout },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  { path: "/theme", exact: true, name: "Theme", component: Colors },
  {
    path: "/theme/colors",
    name: "Colors",
    component: Colors,
  },
  { path: "/theme/typography", name: "Typography", component: Typography },
  {
    path: "/base",
    exact: true,
    name: "Base",
    component: Cards,
  },
  { path: "/base/cards", name: "Cards", component: Cards },
  {
    path: "/base/forms",
    name: "Forms",
    component: Forms,
  },
  { path: "/base/switches", name: "Switches", component: Switches },
  {
    path: "/base/tables",
    name: "Tables",
    component: Tables,
  },
  { path: "/base/tabs", name: "Tabs", component: Tabs },
  {
    path: "/base/breadcrumbs",
    name: "Breadcrumbs",
    component: Breadcrumbs,
  },
  { path: "/base/carousels", name: "Carousel", component: Carousels },
  {
    path: "/base/collapses",
    name: "Collapse",
    component: Collapses,
  },
  { path: "/base/dropdowns", name: "Dropdowns", component: Dropdowns },
  {
    path: "/base/jumbotrons",
    name: "Jumbotrons",
    component: Jumbotrons,
  },
  { path: "/base/list-groups", name: "List Groups", component: ListGroups },
  {
    path: "/base/navbars",
    name: "Navbars",
    component: Navbars,
  },
  { path: "/base/navs", name: "Navs", component: Navs },
  {
    path: "/base/paginations",
    name: "Paginations",
    component: Paginations,
  },
  { path: "/base/popovers", name: "Popovers", component: Popovers },
  {
    path: "/base/progress-bar",
    name: "Progress Bar",
    component: ProgressBar,
  },
  { path: "/base/tooltips", name: "Tooltips", component: Tooltips },
  {
    path: "/buttons",
    exact: true,
    name: "Buttons",
    component: Buttons,
  },
  { path: "/buttons/buttons", name: "Buttons", component: Buttons },
  {
    path: "/buttons/button-dropdowns",
    name: "Button Dropdowns",
    component: ButtonDropdowns,
  },
  {
    path: "/buttons/button-groups",
    name: "Button Groups",
    component: ButtonGroups,
  },
  {
    path: "/buttons/brand-buttons",
    name: "Brand Buttons",
    component: BrandButtons,
  },
  { path: "/icons", exact: true, name: "Icons", component: CoreUIIcons },
  {
    path: "/icons/coreui-icons",
    name: "CoreUI Icons",
    component: CoreUIIcons,
  },
  { path: "/icons/flags", name: "Flags", component: Flags },
  {
    path: "/icons/font-awesome",
    name: "Font Awesome",
    component: FontAwesome,
  },
  {
    path: "/icons/simple-line-icons",
    name: "Simple Line Icons",
    component: SimpleLineIcons,
  },
  {
    path: "/notifications",
    exact: true,
    name: "Notifications",
    component: Alerts,
  },
  { path: "/notifications/alerts", name: "Alerts", component: Alerts },
  {
    path: "/notifications/badges",
    name: "Badges",
    component: Badges,
  },
  { path: "/notifications/modals", name: "Modals", component: Modals },
  {
    path: "/widgets",
    name: "Widgets",
    component: Widgets,
  },
  { path: "/charts", name: "Charts", component: Charts },

  { path: "/users", exact: true, name: "Contestant Manager", component: Users },
  {
    path: "/users/add",
    exact: true,
    name: "Add User",
    component: UserAdd,
  },
  {
    path: "/users/get/:id",
    exact: true,
    name: "Get User Details",
    component: UserData,
  },

  { path: "/videos", exact: true, name: "Uploaded Videos", component: Videos },
  {
    path: "/videos/video-list/:id",
    exact: true,
    name: "Get Video Details",
    component: VideoData,
  },

  {
    path: "/usernotifications/send",
    exact: true,
    name: "Notification Manager",
    component: NotificationSend,
  },

  {
    path: "/content",
    exact: true,
    name: "Content Manager",
    component: Contents,
  },
  // {
  //   path: "/package",
  //   exact: true,
  //   name: "Package Manager",
  //   component: Packages,
  // },
  {
    path: "/content/get/:id",
    exact: true,
    name: "Content Manager Details",
    component: ContentsView,
  },
  {
    path: "/content/edit/:id",
    exact: true,
    name: "Edit Content Manager",
    component: ContentsEdit,
  },

  {
    path: "/package",
    exact: true,
    name: "Subscription Manager",
    component: Packages,
  },
  {
    path: "/package/add",
    exact: true,
    name: "Add Subscription/Package",
    component: PackageAdd,
  },

  {
    path: "/abusive-video",
    exact: true,
    name: "Abusive Videos",
    component: AbuseVideos,
  },

  {
    path: "/users/edit/:id",
    exact: true,
    name: "Edit User Details",
    component: UserAdd,
  },
  {
    path: "/profile",
    exact: true,
    name: "Admin Manager",
    component: AdminProfile,
  },
  {
    path: "/profile/edit/:id",
    exact: true,
    name: "Edit Admin Profile",
    component: AdminEdit,
  },
  {
    path: "/password/forgot",
    exact: true,
    name: "Enter Email",
    component: ForgotPassword,
  },
  {
    path: "/password/reset",
    exact: true,
    name: "Enter Password",
    component: ResetPassword,
  },

  /*{ path: '/slider', exact: true, name: 'Slider Images', component: Slider },
  { path: '/slider/add', exact: true, name: 'Add Contest Category', component: SliderAddNew },
  { path: '/slider/edit/:id', exact: true, name: 'Edit Slider', component: SliderAdd },*/

  { path: "/admins", exact: true, name: "Sub Admin", component: Admins },
  {
    path: "/admins/add",
    exact: true,
    name: "Add Sub Admin",
    component: AdminAdd,
  },
  {
    path: "/admins/get/:id",
    exact: true,
    name: "Sub Admin Details",
    component: AdminData,
  },
  {
    path: "/admins/edit/:id",
    exact: true,
    name: "Edit Sub Admin Details",
    component: AdminAdd,
  },

  {
    path: "/settings",
    exact: true,
    name: "Manage Default Settings",
    component: Settings,
  } /*{ path: '/aboutus', exact: true, name: 'About Us', component: AboutUs },
  { path: '/condition', exact: true, name: 'Terms', component: Condition },
  { path: '/privacy', exact: true, name: 'Privacy', component: Privacy },
  { path: '/legality', exact: true, name: 'Legality ', component: Legality },*/,
  { path: "/contactus", exact: true, name: "Contact Us", component: ContactUs },
  {
    path: "/usernotifications/receive",
    exact: true,
    name: "Notification",
    component: NotificationReceived,
  },

  {
    path: "/templates",
    exact: true,
    name: "Email Templates",
    component: Templates,
  },
  {
    path: "/templates/get/:id",
    exact: true,
    name: "Email Template Details",
    component: TemplatesView,
  },
  {
    path: "/templates/edit/:id",
    exact: true,
    name: "Edit Email Template",
    component: TemplatesEdit,
  },

  {
    path: "/reports/contestantreport",
    exact: true,
    name: "Contestants Report",
    component: ContestantReport,
  },
  {
    path: "/reports/videoreport",
    exact: true,
    name: "Video Report",
    component: VideoReport,
  },
  {
    path: "/reports/winnerreport",
    exact: true,
    name: "Winners Report",
    component: WinnerReport,
  },
  {
    path: "/reports/subscriptionreport",
    exact: true,
    name: "Subscriptions Report",
    component: SubscriptionReport,
  },
  {
    path: "/reports/subscriptionreport/:id",
    exact: true,
    name: "User Subscription Detail",
    component: SubscriptionList,
  },
  {
    path: "/reports/packagereport",
    exact: true,
    name: "Packages Report",
    component: PackageReport,
  },
    // {
  //   path: "/reports/subscriptionreport",
  //   exact: true,
  //   name: "Subscription Report",
  //   component: SubscriptionReport,
  // },
  {
    path: "/reports/earningreport",
    exact: true,
    name: "Earning Report",
    component: EarningReport,
  },
  {
    path: "/reports/cat-winner",
    exact: true,
    name: "Category Winners Report",
    component: CatWinnerReport,
  },

  

  // E-Commerce Start
  {
    path: "/category",
    exact: true,
    name: "Category Manager",
    component: Category,
  },
  {
    path: "/category/add",
    exact: true,
    name: "Add Category",
    component: CategoryAdd,
  },
  {
    path: "/category/edit/:id",
    exact: true,
    name: "Edit Category",
    component: CategoryAdd,
  },

  {
    path: "/sub-category",
    exact: true,
    name: "Category Manager",
    component: SubCategory,
  },
  {
    path: "/sub-category/add",
    exact: true,
    name: "Add Sub Category",
    component: SubCategoryAdd,
  },
  {
    path: "/sub-category/edit/:id",
    exact: true,
    name: "Edit Sub Category",
    component: SubCategoryAdd,
  },

  {
    path: "/product",
    exact: true,
    name: "Products Manager",
    component: Product,
  },
  {
    path: "/product/add",
    exact: true,
    name: "Add Product",
    component: ProductAdd,
  },
  {
    path: "/product/edit/:id",
    exact: true,
    name: "Edit Product",
    component: ProductAdd,
  },
  {
    path: "/product/get/:id",
    exact: true,
    name: "Get Product Details",
    component: ProductData,
  },
  {
    path: "/leader-board",
    exact: true,
    name: "Leaderboard Manager",
    component: Leaderboard,
  },
  {
    path: "/leader-board/edit/:id",
    exact: true,
    name: "Update Leaderboard",
    component: LeaderEdit,
  },
  {
    path: "/promocode",
    exact: true,
    name: "Promocode Manager",
    component: Promocode,
  },
  {
    path: "/promocode/add",
    exact: true,
    name: "Add Promocode",
    component: PromocodeAdd,
  },
  {
    path: "/promocode/edit/:id",
    exact: true,
    name: "Edit Promocode",
    component: PromocodeAdd,
  },
  {
    path: "/promocode/get/:id",
    exact: true,
    name: "Get Promocode Details",
    component: PromocodeData,
  },

  {
    path: "/service-charge",
    exact: true,
    name: "Service Charges",
    component: ServiceCharges,
  },
  { path: "/orders", exact: true, name: "Orders Manager", component: Orders },
  {
    path: "/orders/get/:id",
    exact: true,
    name: "Get Order Details",
    component: OrderData,
  },
  { path: "/influencer-manager", exact: true, name: "Influencer Manager", component: Referrals },
  { path: "/influencer-price-update", exact: true, name: "Influencer Price Update", component: ReferralPriceEdit },  
  {
    path: "/earning",
    exact: true,
    name: "Earning Manager",
    component: Earning,
  },
  {
    path: "/review-rating",
    exact: true,
    name: "Review & Rating Manager",
    component: ReviewRating,
  },
  { path: "/roles", exact: true, name: "Role Manager", component: Roles },

  {
    path: "/admin-roles",
    exact: true,
    name: "Admin Role Manager",
    component: AdminRole,
  },

  {
    path: "/setting-cancel-fee",
    exact: true,
    name: "Setting Cancel Fee",
    component: SettingCancelFee,
  },
].filter((route) => {
  if (!routerNeedPermissions.includes(route.path)) {
    return true;
  } else {
    const currentRouterWithPermission = routeWithPermissions.find(
      (testingRoute) => testingRoute.path === route.path
    );
    return !!currentRouterWithPermission?.permissions.some(
      (testingPermission) => permissions.includes(testingPermission)
    );
  }
});

export default routes;
