import React, {useEffect, useState} from 'react'
import styled, {css} from "styled-components";
import {Button, Input, Popover} from "reactstrap";

const Role = ({role, active, onSelectRole, onEditRole, onDeleteRole, setIsEditMode}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editingValue, setEditingValue] = useState(role.name)

  const [popover, setPopover] = useState(false);

  const toggle = () => setPopover(!popover);

  useEffect(() => {
    setIsEditMode(isEditing)
  }, [isEditing])


  return <RoleBox
    className='py-3 px-1 border-bottom'
    active={active}
    onClick={() => onSelectRole(role)}>
    {
      isEditing ? <EditBox>
          <Input
            onChange={e => setEditingValue(e.target.value)}
            value={editingValue}
          />
          <Button color='primary' size='sm' disabled={!editingValue} onClick={() => {
            onEditRole(role.id, editingValue)
            setIsEditing(false)
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"/>
            </svg>
          </Button>
          <Button color='danger' size='sm' onClick={() => {
            setIsEditing(false)
            setEditingValue(role.name)
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </Button>
        </EditBox> :
        <ContentBox>
          <p className='my-0 px-2'>{role.name}</p>
          <div>
            <Button color='link' size='sm' onClick={() => setIsEditing(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
              </svg>
            </Button>
            <Button onClick={() => setPopover(true)} type='button' id={`deletePopover-${role.id}`} color='link'
                    size='sm'>
              <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
              </svg>
            </Button>
          </div>
        </ContentBox>
    }
    <Popover
      isOpen={popover}
      toggle={toggle}
      flip
      target={`deletePopover-${role.id}`}
      trigger="click"
      placement='top'
    >
      <PopoverContent>
        <span style={{fontSize: 14}}>Do you want delete?</span>
        <Actions>
          <Button size='sm' color='danger' onClick={() => toggle()}>No</Button>
          <Button size='sm' color='primary' onClick={() => {
            onDeleteRole(role.id)
            toggle()
          }}>Yes</Button>
        </Actions>
      </PopoverContent>
    </Popover>
  </RoleBox>
}

const RoleBox = styled('div')`
  cursor: pointer;
  width: 100%;

  ${props => props.active && css`
    background: #3a3838 !important;

    &:hover {
      background: #3a3838 !important;
    }
  `}
  &:hover {
    background: #3a3838;
  }
`
const EditBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
`
const ContentBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
`

const PopoverContent = styled('div')`
  padding: 20px 15px 10px 15px;
  border-radius: 10px;
  border: 1px solid transparent;
  width: 200px;
`
const Actions = styled('div')`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 15px 0 5px 0;
  justify-content: end;
`

export default Role
