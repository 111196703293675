import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

class ContactUsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: this.props.contactUsData,
        };
    
    } 

   render( ){
        return (
            
            <Card>
            <CardBody>
                {ReactHtmlParser(this.state.content)}
            </CardBody>
            </Card>
                    
        );
    }
}

export default ContactUsView;
