import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { isEmpty } from 'lodash';

import {  apiBaseUrl } from  '../../../configs/configs';
import SupportView from './SupportView';

class Support extends Component {
  constructor(props) {
    super(props);

    this.state = {

      contentData: {},
      id: ''
        
    };
    
    this.getContent().then((res) => {
      console.log('response', res);
      this.setState({ contentData: res.result, id: res.result.id });
    }).catch(e => console.log(e));
    
  }

  async getContent(){

    const howtoplayApi = `${apiBaseUrl}/aboutus`;
    const response = await fetch(howtoplayApi);
    if(response.status === 200){
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      return new Error();
    }
  }  

  render() {
    let aboutUsData = (this.state.contentData && this.state.contentData.aboutUs) || {};
    try{
        aboutUsData = window.atob(aboutUsData);
    } catch(err) {
        console.log(err);
    }
    
    
    return (

      <div className="container">
        <Card>
          <CardBody>
            {!isEmpty(aboutUsData) && <SupportView SupportData={aboutUsData}/>}
            TEST
          </CardBody>
        </Card>
      </div>
      
        
    );
  }
}

export default Support;
