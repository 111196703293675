import React, { Component } from 'react';
import { Alert, Input, Collapse, Button, Card, CardBody, Col, Row, Table, Form } from 'reactstrap';
import { isEmpty } from 'lodash';
import cookie from 'react-cookies';
import ReactHtmlParser from 'react-html-parser';
import { apiBaseUrl } from '../../../configs/configs';

class CreateTeam extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: this.props.createTeamData,
            collapse: false,
            isApplyClass: false,
            
        };
        
        this.toggle = this.toggle.bind(this);
        
        
    } 

    toggle() {
        this.setState({ collapse: !this.state.collapse, isApplyClass: !this.state.isApplyClass });
      }

    render( ){
        
        return (
            
            <Row>
                <Col sm="12">
                    <Button color="primary" className={this.state.isApplyClass ? 'show-dropdown-icon' : ''} onClick={this.toggle} style={{ marginBottom: '1rem' }} size={"sm"} block>Creating Your Team</Button>
                    <Collapse isOpen={this.state.collapse}>
                        <Card>
                        <CardBody>
                            {ReactHtmlParser(this.state.content)}
                        </CardBody>
                        </Card>
                    </Collapse>
                </Col>
            </Row>
        );
    }
}

export default CreateTeam;
