import React, { Component } from 'react';
import { Alert, Button, FormFeedback, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Redirect } from 'react-router';
import cookie from 'react-cookies';
import { isEmpty } from 'lodash';

import FormValidator from '../../FormValidator/FormValidator';
import { loginValidationRules } from './validations';
import { apiBaseUrl } from '../../../configs/configs';
import logo from '../../../assets/img/brand/logo.png';

class Login extends Component {

  constructor(props) {
    super(props);

    this.validator = new FormValidator(loginValidationRules);
    this.state = {
      data: false,
      email: '',
      password: '',
      forgotPasswordClick: false,
      serverValidationDone: false,
      validation: this.validator.valid(),
      alertClass: '',
      alertMessage: '',
      inlineLoading: false
    };

    this.submitted = false;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.onForgotPasswordHandle = this.onForgotPasswordHandle.bind(this);
  }


  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  componentDidMount() {
    cookie.remove('permissions')
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {

    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.setState({ inlineLoading: true }, async () => {
        const loginApi = `${apiBaseUrl}/admin/login`;
        const requestBody = { email: this.state.email, password: this.state.password };
        const response = await fetch(loginApi, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        console.log("response: ", response);
        const res = await response.json();
        if (response.status === 200) {
          cookie.save('email', this.state.email)
          cookie.save('password', this.state.password)
          cookie.save('two_factor_secret', res.result.adminData.two_factor_secret || '');
          cookie.save('has_secret', res.result.adminData.hasSecret || false);
          this.props.history.push('/two-factor-auth');
          this.props.history.go(0)
          return;
        } else if (response.status === 401) {

          this.setState({ displayAlert: true, alertClass: 'danger', alertMessage: 'Email/Password are not correct.', inlineLoading: false });

        } else if (response.status === 422) {
          const errors = (res.result && res.result.errors) || [];
          if (!isEmpty(errors)) {

            errors.map((error) => {

              if (this.state.validation.hasOwnProperty(error.param) === true) {

                let validationClone = Object.assign({}, this.state.validation);
                validationClone.isValid = false;
                validationClone[error.param].isInvalid = true;
                validationClone[error.param].message = error.msg;

                this.setState({ validation: validationClone, serverValidationDone: true, inlineLoading: false });

              }

            });

          } else {

            this.setState({ displayAlert: true, alertClass: 'danger', alertMessage: res.msg, inlineLoading: false });

          }


        } else {

          this.setState({ displayAlert: true, alertClass: 'danger', alertMessage: res.msg || 'Something went wrong.', inlineLoading: false });

        }

      });

    }

  }

  onForgotPasswordHandle() {
    this.setState({ forgotPasswordClick: true });
  }

  inlineLoading() {

    if (this.state.inlineLoading) {
      return <i className="fa fa-spinner fa-spin" ></i>

    }

  }

  render() {
    let validation = this.submitted && (this.state.serverValidationDone === false) ?
      this.validator.validate(this.state) :
      this.state.validation
    if (this.state.data) {
    }
    if (this.state.forgotPasswordClick) {
      return <Redirect to='/password/forgot' />
    }
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      {this.state.displayAlert === true ? <div><Alert color={this.state.alertClass}>
                        {this.state.alertMessage}
                      </Alert></div> : null}
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={this.handleEmailChange} invalid={validation.email.isInvalid} type="text" placeholder="Email" name="email" autoComplete="email" />
                        <FormFeedback>{validation.email.message}</FormFeedback>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={this.handlePasswordChange} invalid={validation.password.isInvalid}
                          type="password" placeholder="Password" name="password" autoComplete="current-password" />
                        <FormFeedback>{validation.password.message}</FormFeedback>
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button style={{ background: '#FFC600', borderColor: '#FFC600' }}
                            className="px-4">Login {this.inlineLoading()}</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0" onClick={this.onForgotPasswordHandle}>Forgot password?</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2><img src={logo} width="200px" /></h2>

                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
