import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { isEmpty } from 'lodash';
import cookie from 'react-cookies';

import { apiBaseUrl } from '../../../configs/configs';
import PrivacyView from './PrivacyView';
import "../styles.css"

class Privacy extends Component {
  constructor(props) {

    super(props);

    this.state = {

      contentData: {},
      id: ''

    };

    this.getContent().then((res) => {
      console.log('response', res);
      this.setState({ contentData: res.result.contentList[3].description, id: res.result.contentList[3].id});
    }).catch(e => console.log(e));

  }

  async getContent() {

    const howtoplayApi = `${apiBaseUrl}/content/list`;
    const token = cookie.load('token');
    const response = await fetch(howtoplayApi, {headers: {"token": token}});
    if (response.status === 200) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      return new Error();
    }
  }

  render() {
    // let aboutUsData = (this.state.contentData && this.state.contentData.content) || {};
    // try {
    //   aboutUsData = window.atob(aboutUsData);
    // } catch (err) {
    //   console.log(err);
    // }

    const aboutUsData = (this.state.contentData && this.state.contentData) || '';
    const html = `${aboutUsData}`
    function createMarkup() {
      return {__html: html != '[object Object]' ? html : ''
    };
    }
    return (
        <div dangerouslySetInnerHTML={createMarkup()} className='content'/>
    );
  }
}

export default Privacy;
