import React, { Component } from 'react';
import { Button, Input, Card, CardBody, CardHeader, FormGroup, Col, Row, Label } from 'reactstrap';
import cookie from 'react-cookies';
import { isEmpty } from 'lodash';
import { Redirect } from 'react-router';
import Pagination from "react-js-pagination";

import UserTable from './SubscriptionTable';
import { apiBaseUrl } from '../../configs/configs';
import Alert from '../AlertMessage/Alert';
import SubscriptionListTable from './SubscriptionListTable';

class SubscriptionList extends Component {

    constructor(props) {
        super(props);
        console.log("props--------->",props)

        this.state = {
            defaultPage: 1,
            defaultSize: 10,
            userId:props?.location?.state?.userId,
            userName:props?.location?.state?.name,
            subscriptionList: [],
            paginationData: {},
            currentPage: 1,
            selectedMonth: '',
            selectedQuarter: '',
            selectedYear: '',
            alertClass: '',
            alertMessage: '',
            searchText: false,
            loading: false,
            alert: {}
        };
        this.filterMonthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.filterQuarterList = ['First', 'Second', 'Third', 'Four'];
        this.filterYearList = ['2019', '2020', '2021', '2022', '2023', '2024', '2025'];
        this.getSubscriptionList = this.getSubscriptionList.bind(this);
        // this.onSelectMonth = this.onSelectMonth.bind(this);
        // this.onSelectQuarter = this.onSelectQuarter.bind(this);
        // this.onSelectYear = this.onSelectYear.bind(this);
        // this.filterData = this.filterData.bind(this);
        // this.searchBy = this.searchBy.bind(this);
        // this.resetData = this.resetData.bind(this);
    }

    componentDidMount() {

        this.setState({ loading: true }, function () {
            this.getSubscriptionList()
        });

    }

    async getSubscriptionList(page = 1, size = 1) {
        const token = cookie.load('token');
        console.log("token------------>", token)
        let params = {
            "user_id": this.state.userId ?? 0
        };
        const options = {
            method: "POST",
            headers: {
                token: token,
                "Content-Type": "application/json",
                //   Accept: "application/json",
            },
            body: JSON.stringify(params),
        };
        const getUserDataApi = `${apiBaseUrl}/users/subscription-list`;
        const response = await fetch(getUserDataApi, options);
        // const response = await fetch(getUserDataApi, {method: "POST", headers: { "token": token }});
        if (response.status === 200) {
            const res = await response.json(); console.log(res);
            console.log("res----->", res)
            this.setState({ loading: false, subscriptionList: res.result });
        } else if (response.status === 401) {
            cookie.remove('token');
            return <Redirect to='/login' />
        } else {
            this.setState({ loading: false });
            return new Error();
        }
    }



    loading() {
        if (this.state.loading) {
            return (
                <div>
                    <div className={"text-center"} colSpan="8">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
    }

    render() {

        const subscriptionList = this.state.subscriptionList;
        const paginationData = this.state.paginationData;

        return (
            <div className="animated fadeIn">
                <Row className="user_info">
                    <Col xl={12}>
                        <Card>
                            <CardHeader>
                                <i className="fa fa-align-justify"></i> {this.state.userName} <small className="text-muted"></small>
                            </CardHeader>
                            <CardBody>
                                {/* <Row>

                  <Col sm={12} md={4} lg={4} xl={3}>
                    <Label for="month">Month</Label>
                    {this.createMonthSelect()}
                  </Col>
                  
                  <Col sm={12} md={4} lg={4} xl={3}>
                    <Label for="quarter">Quarter</Label>
                    {this.createQuarterSelect()}
                  </Col>

                  <Col sm={12} md={4} lg={4} xl={3}>
                    <Label for="year">Year</Label>
                    {this.createYearSelect()}
                  </Col>

                  <Col xs={4} md={2} lg={2} xl={1}>
                    <Label for="status" className="empty_lbl">&nbsp;</Label>
                    {this.state.searchText && (
                      <a className="btn btn-success" href='javascript:void(0);'> <i className="fa fa-spinner fa-spin"></i> loading</a>
                    )}
                    {!this.state.searchText && (
                      <a className="btn btn-success" href='javascript:void(0);' onClick={this.filterData} >Search</a>
                    )}


                  </Col>
                  <Col xs={4} md={2} lg={2} xl={1}>
                    <Label for="status" className="empty_lbl">&nbsp;</Label>
                    <a className="btn btn-danger" href='javascript:void(0);' onClick={this.resetData} >Reset</a>
                  </Col>

                </Row> */}

                                <br />

                                {!isEmpty(this.props.location.alert) && <Alert data={this.props.location.alert} />}

                                {<SubscriptionListTable subscriptionList={subscriptionList} />}
                                {isEmpty(subscriptionList) && <Card align="centre"><CardBody><center><b>No Subscription Data Found</b></center></CardBody></Card>}
                                {this.loading()}

                                {!isEmpty(paginationData) && <Pagination
                                    activeClass={"page-item active"}
                                    activeLinkClass={"page-link"}
                                    itemClass={"page-item"}
                                    linkClass={"page-link"}
                                    activePage={paginationData.currentPage}
                                    itemsCountPerPage={this.state.defaultSize}
                                    totalItemsCount={this.state.defaultSize * paginationData.totalPage}
                                    pageRangeDisplayed={5}
                                    onChange={this.searchBy}
                                />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default SubscriptionList;
