// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content{
    background-color:#000;
    overflow: hidden ;
    width: 100%;
}
.content p {
    white-space: break-spaces ; 
}`, "",{"version":3,"sources":["webpack://./src/views/Pages/styles.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,WAAW;AACf;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".content{\r\n    background-color:#000;\r\n    overflow: hidden ;\r\n    width: 100%;\r\n}\r\n.content p {\r\n    white-space: break-spaces ; \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
