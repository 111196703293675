import React from 'react'
import styled from "styled-components";
import {Button, Input} from "reactstrap";

const Permissions = ({rolePermissions, permissions, onAssignPermissionToAdmin, seRolePermissions}) => {


  const handleRemoveRolePermission = (value) => {
    seRolePermissions(rolePermissions.filter(rolePermission => rolePermission !== value))
  }

  const handleAddRolePermission = (value) => {
    const testingPermission = rolePermissions.find((testing) => testing === value)
    if (testingPermission) {
      seRolePermissions([...rolePermissions].filter(rolePermission => rolePermission !== value))
    } else {
      seRolePermissions([...rolePermissions, value])
    }
  }

  const handleAssign = (event) => {
    event.preventDefault()
    onAssignPermissionToAdmin(rolePermissions)
  }

  return <div style={{padding: "5px 0"}}>
    {!!rolePermissions?.length && <CurrentPermissions>
      <Title> Rights </Title>
      <PermissionBox>
        {
          rolePermissions.map((permission) => <Permission key={permission}>
            <div/>
            <p className=" m-0" style={{fontSize: 14}}>{permission}</p>
            <RemoveIcon onClick={() => handleRemoveRolePermission(permission)}>
              <svg xmlns="http://www.w3.org/2000/svg" style={{width: 20, height: 20, color: '#ea5e5e'}} fill="none"
                   viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </RemoveIcon>
          </Permission>)
        }
      </PermissionBox>
      <Button onClick={(event) => handleAssign(event)} color='danger'
              style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
        <svg xmlns="http://www.w3.org/2000/svg" style={{width: 30, height: 30}} fill="none" viewBox="0 0 24 24"
             stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"/>
        </svg>
        <span style={{fontSize: 16, fontWeight: 'bold'}}>Confirm</span>
      </Button>

    </CurrentPermissions>}
    <div>
      <Title> Add more Rights </Title>
      <Box>
        {permissions.map((permission, index) => <ItemBox direction='column' key={permission.value}>
          <Item className='d-flex align-items-center border-bottom  pr-3 py-1'
                onClick={() => handleAddRolePermission(permission.value)}>
            <CheckBox type='checkbox' checked={rolePermissions && rolePermissions.includes(permission.value)}/>
            <div className="">
              <p className=" m-0" style={{fontSize: 14}}>{permission.value}</p>
              <small style={{fontSize: 12, fontStyle: 'italic'}}>{permission.title}</small>
            </div>
          </Item>
        </ItemBox>)}
      </Box>
    </div>
  </div>
}

const CurrentPermissions = styled('div')`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const RemoveIcon = styled('div')`
  cursor: pointer;
  width: 24px;
  height: 24px;

  &:hover > svg {
    color: #c70303 !important;
  }
`

const PermissionBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`

const Permission = styled('div')`
  padding: 5px 10px;
  border-radius: 3px;
  border: 0.5px solid #e6e6e6;
  background: #080402;
  width: auto;
  display: flex;
  align-items: center;
  gap: 10px;
`

const Title = styled('p')`
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px 0 10px;
  margin: 0;
`

const ItemBox = styled('div')`
  display: flex;
  flex-direction: column;
  width: 47%;
`

const Box = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
  border-top: 2px solid #e6e6e6;
  margin-top: 5px
`

const Item = styled('div')`
  cursor: pointer;
  width: 100%;
  gap: 10px;
  padding-left: 40px;

  &:hover {
    background: #3a3838;
  }
`
const CheckBox = styled(Input)`
  position: relative;
`
export default Permissions
