import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.css';
// Styles
// CoreUI Icons Set ddd
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, HowToPlay, AboutUs, Legality, ContactUs, Support, Terms, Privacy, Disclaimer, InfluencerCode } from './views/Pages';
import TwoFactorAuth from './views/Pages/Login/TwoFactorAuth';
/* import HowToPlay from './views/Pages/HowToPlay/HowToPlay';
import AboutUs from './views/Pages/AboutUs/AboutUs'; */
// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/two-factor-auth" name="Two-Factor Authentication Page" component={TwoFactorAuth} />
          <Route exact path="/how-to-play" name="How To Play" component={HowToPlay} />
          <Route exact path="/about-us" name="About Us" component={AboutUs} />
          <Route exact path="/disclaimer" name="Disclaimer" component={Disclaimer} />
          <Route exact path="/contact-us" name="Contact Us" component={ContactUs} />
          <Route exact path="/legality-view" name="Legality" component={Legality} />
          <Route exact path="/support" name="How To Play" component={Support} />
          <Route exact path="/terms-view" name="How To Play" component={Terms} />
          <Route exact path="/privacy-view" name="How To Play" component={Privacy} />
          <Route exact path="/influencer-code-view" name="How To Play" component={InfluencerCode} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/" name="Home" component={DefaultLayout} />
          {/* <Route exact path="/register" name="Register Page" component={Register} /> */}
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
