const loginValidationRules = [
    { 
      field: 'email', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Email is required.' 
    },
    { 
      field: 'email',
      method: 'isEmail', 
      validWhen: true, 
      message: 'Please provide a valid Email.'
    },
    
    { 
      field: 'password', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Password is required.'
    },
    { 
        field: 'password', 
        method: 'isLength', 
        args: [{min: 6, max: 10}],
        validWhen: true, 
        message: 'Password length must be between 6 to 10.'
    },
    
];

const twoFactorAuth = [
  { 
    field: 'code', 
    method: 'isEmpty', 
    validWhen: false, 
    message: 'Code is required.' 
  },
  { 
    field: 'code',
    method: 'isLength', 
    args: [{min: 6, max: 10}],
    validWhen: true, 
    message: 'Please provide a correct code.'
  },
]

const forgotValidationRules = [
    { 
      field: 'email', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Email is required.' 
    },
    { 
      field: 'email',
      method: 'isEmail', 
      validWhen: true, 
      message: 'Please provide a valid Email.'
    },
    
];

module.exports = {

    loginValidationRules,
    forgotValidationRules,
    twoFactorAuth

}
