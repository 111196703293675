import validator from 'validator';

class FormValidator {
  constructor(validations) {
    this.validations = validations;
  }

  validate(state) {
    let validation = this.valid();

    try{
      this.validations instanceof Array && this.validations.forEach(rule => {

        if (!validation[rule.field].isInvalid) {
          let field_value = (state[rule.field] && state[rule.field].toString()) || '';
          if(state[rule.field] === 0){
            field_value = '0';
          }
          field_value = field_value.trim();
          const args = rule.args || [];
          const validation_method = 
            typeof rule.method === 'string' ?
            validator[rule.method] : 
            rule.method
                
          if(validation_method(field_value, ...args) !== rule.validWhen) {
            validation[rule.field] = { isInvalid: true, message: rule.message }
            validation.isValid = false;
          }
        }
      });

      return validation;
    }
    catch(e){
      console.log(e);
      return validation;
    }  
  }

  valid() {
    const validation = {}

    try{

      this.validations.map(rule => (
      validation[rule.field] = { isInvalid: false, message: '' }
    
      ));

      return { isValid: true, ...validation };
    } catch(e) {
      console.log(e);
      return {isValid: true};
    }
  }
}

export default FormValidator;