import React, {useEffect} from 'react'
import styled from "styled-components";
import {Button, Input} from "reactstrap";

const Roles = ({userSelected, selectedRole, roles, onChangeRoleName, onUpdateRole}) => {

  const [editMode, setEditMode] = React.useState(false);
  const [previousRoleId, setPreviousRoleId] = React.useState(null);

  useEffect(() => {
    setEditMode(false)
  }, [userSelected])

  const handleUpdateRole = (event) => {
    event.preventDefault()
    setEditMode(false)
    onUpdateRole(selectedRole)
  }


  return <Box>
    <CurrentRoleBox>
      <CurrentRoleLabel>Current role:</CurrentRoleLabel>
      {editMode ? <Form>
        <Input
          value={selectedRole?.id}
          style={{width: 300, cursor: 'pointer', boxShadow: 'none'}}
          id="roles"
          name="select"
          type="select"
          onChange={e => onChangeRoleName(parseInt(e.target.value))}
        >
          {!!roles.length && roles.map(role => <option key={role.id} value={role.id}>{role.name}</option>)}
        </Input>
        <Button type='submit' color='primary' size='sm' onClick={(event) => handleUpdateRole(event)}>
          <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none" viewBox="0 0 24 24"
               stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"/>
          </svg>
        </Button>
        <Button color='danger' size='sm' onClick={() => {
          onChangeRoleName(previousRoleId)
          setEditMode(!editMode)}
        }>
          <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none" viewBox="0 0 24 24"
               stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </Button>
      </Form> : <CurrentRole notYet={!selectedRole}>{selectedRole ? selectedRole.name : 'Not yet!'}</CurrentRole>}
      {!editMode && <div>
        <Button color='link' size='sm' onClick={() => {
          setPreviousRoleId(selectedRole?.id)
          setEditMode(!editMode)
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none" viewBox="0 0 24 24"
               stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
          </svg>
        </Button>
      </div>}
    </CurrentRoleBox>
  </Box>
}

const Box = styled('div')`
  padding: 15px 15px 0 15px;
`

const CurrentRoleLabel = styled('span')`
  font-weight: bold;
  font-size: 14px;
  color: white;
  width: 110px;
`

const CurrentRole = styled('span')`
  font-size: 18px;
  color: ${props => props.notYet ? '#ff0000' : '#136FDA'};
  font-weight: 500;
  cursor: pointer;
`
const CurrentRoleBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Form = styled('form')`
  display: flex;
  align-items: center;
  gap: 10px;
`

export default Roles
