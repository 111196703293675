import React, { Component } from 'react';
import { Alert, Button, FormFeedback, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Redirect } from 'react-router';
import cookie from 'react-cookies';
import { isEmpty } from 'lodash';
import { twoFactorAuth } from './validations';
import FormValidator from '../../FormValidator/FormValidator';
import { apiBaseUrl } from '../../../configs/configs';
import logo from '../../../assets/img/brand/logo.png';

class TwoFactorAuth extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator(twoFactorAuth);
    this.state = {
      data: false,
      email: '',
      code: '',
      validator: new FormValidator(twoFactorAuth),
      password: '',
      forgotPasswordClick: false,
      serverValidationDone: false,
      validation: this.validator.valid(),
      alertClass: '',
      alertMessage: '',
      inlineLoading: false,
      twoFactorSecret: '',
      hasSecret: false,
      qrCodeImage: ''
    };

    this.submitted = false;
    this.state.email = cookie.load('email') || '';
    this.state.password = cookie.load('password') || '';
    this.state.twoFactorSecret = cookie.load('two_factor_secret') || '';
    this.state.hasSecret = cookie.load('has_secret') || false;
    this.handleCodeChange = this.handleCodeChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCodeChange(event) {
    this.setState({ code: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const validation = this.state.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      this.setState({ inlineLoading: true }, async () => {
        await this.verifyOtp();
      });
    }
  }

  async componentDidMount() {
    cookie.remove('permissions')
    await this.getProductImageQR();
  }

  async verifyOtp() {
    let code = this.state.code
    const verifyCodeApi = `${apiBaseUrl}/admin/verify2FA`;
    const requestBody = { otpToken: code, email: this.state.email, secret: this.state.twoFactorSecret };
    const response = await fetch(verifyCodeApi, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const res = await response.json();
    if (response.status === 200) {
      if (res.isValid === true) {
        await this.loadNextRequest();
      } else {
        this.setState({ displayAlert: true, alertClass: 'danger', alertMessage: res.msg, inlineLoading: false });
      }
    }
  }

  async loadNextRequest() {
    const loginApi = `${apiBaseUrl}/admin/login`;
    const requestBody = { email: this.state.email, password: this.state.password };
    const response = await fetch(loginApi, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const res = await response.json();
    if (response.status === 200) {
      const getUserDataApi = `${apiBaseUrl}/admin/get/${res.result && res.result.adminData && res.result.adminData.id}`;

      cookie.save('token', res.result && res.result.token);
      cookie.save('adminId', res.result && res.result.adminData && res.result.adminData.id);
      cookie.save('adminType', res.result && res.result.adminData && res.result.adminData.type);
      cookie.save('accessModules', res.result && res.result.adminData && res.result.adminData.accessModules);

      const responseProfile = await fetch(getUserDataApi, {
        headers: {
          "token": res.result && res.result.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        const res = await responseProfile.json();
        let adminData = res.result;
        cookie.save('adminType', adminData.type || '');
        cookie.save('permissions', adminData.permissions || []);
        cookie.remove('email')
        cookie.remove('password')
      }

      await this.setState({ data: true, inlineLoading: false });

      this.props.history.push('/dashboard')
      this.props.history.go(0)
    }
  }

  async getProductImageQR() {
    let email = cookie.load('email') || '';
    const qrCodeApi = `${apiBaseUrl}/admin/generateImageQR`;
    const requestBody = { username: this.state.email, secret: this.state.twoFactorSecret};
    const response = await fetch(qrCodeApi, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const res = await response.json();
    if (response.status === 200) {
      this.setState({ qrCodeImage: res.QRCodeImage });
    }
  }

  inlineLoading() {

    if (this.state.inlineLoading) {
      return <i className="fa fa-spinner fa-spin" ></i>

    }

  }

  render() {
    let validation = this.submitted && (this.state.serverValidationDone === false) ?
      this.validator.validate(this.state) :
      this.state.validation

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <h1>Enter Authentication Code</h1>
                      {this.state.hasSecret === 'false' ? <h2 style={{ margin: 'auto', marginBottom: 30, textAlign: "center" }}><img src={this.state.qrCodeImage} width="200px" /></h2> : null}
                      {this.state.hasSecret === 'false' ? <p><small>Using Google Authenticator</small></p> : null}
                      {this.state.displayAlert === true ? <div><Alert color={this.state.alertClass}>
                        {this.state.alertMessage}
                      </Alert></div> : null}
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={this.handleCodeChange} invalid={validation.code.isInvalid}
                          type="text" placeholder="Your code number" name="code" />
                        <FormFeedback>{validation.code.message}</FormFeedback>
                      </InputGroup>

                      <Row>
                        <Col xs="6">
                          <Button style={{ background: '#FFC600', borderColor: '#FFC600' }}
                            className="px-4">Verify {this.inlineLoading()}</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2><img src={logo} width="200px" /></h2>

                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default TwoFactorAuth;