import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { AlertList } from "react-bs-notifier";

class Alert extends Component {

    constructor(props) {
        super(props);
        this.state = {
        
            position: this.props.position || "top-right",
            alerts: [],
            timeout: this.props.timeout || 5000,
            message: this.props.message,
            time: 0
        
        };

        this.onAlertDismissed = this.onAlertDismissed.bind(this);

    }

    static getDerivedStateFromProps(props, state) {

        if(!isEmpty(props.data)){

            let propsData = props.data;

            if(state.time !== propsData.time){

                const newAlert = {
                    id: propsData.time,
                    type: propsData.type,
                    headline: propsData.headLine,
                    message: propsData.message,
                  };
    
                return {
                    message: propsData.message,
                    alerts: [newAlert],
                    time: propsData.time
                };

            } else {

                return null;

            }

        } else {

            return null;

        }
        
    }


    onAlertDismissed(alert) {
        const alerts = this.state.alerts;
    
        // find the index of the alert that was dismissed
        const idx = alerts.indexOf(alert);
    
        if (idx >= 0) {
          this.setState({
            // remove the alert from the array
            alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
          });
        }
    }

    render(){
        return (
            <AlertList
              position={this.state.position}
              alerts={this.state.alerts}
              timeout={this.state.timeout}
              dismissTitle="Begone!"
              onDismiss={this.onAlertDismissed}
            />
        )

    }

}    

export default Alert;