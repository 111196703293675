import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';

import logo from '../../assets/img/brand/sidelogo.png';
import { isAccessTo } from '../../helper/helper';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLogout: false,
      redirectToProfile: false
    };
    this.adminType = cookie.load('adminType');
    this.onLogoutHandle = this.onLogoutHandle.bind(this);
    this.profileHandle = this.profileHandle.bind(this);

  }

  onLogoutHandle() {
    cookie.remove('token');
    cookie.remove('permissions')
    this.setState({isLogout: true});
  }

  profileHandle(event) {

    this.setState({ redirectToProfile: true });

  }
  componentWillUnmount() {
    this.setState({redirectToProfile: false});
}
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    if(this.state.isLogout) {
      return <Redirect to='/login' />
    }

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand

          full={{ src: logo, width: 125, height: 50, alt: 'CoreUI Logo' }}
          minimized={{ src: logo, width: 30, height: 30, alt: 'CoreUI Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="d-md-down-none" navbar>
        {(this.adminType === 'admin' || isAccessTo('dashboard')) &&<NavItem className="px-3">
            <NavLink href="#/dashboard">Dashboard</NavLink>
          </NavItem>}
          {/*(this.adminType === 'admin' || isAccessTo('users')) &&<NavItem className="px-3">
            <NavLink href="#/users">Users</NavLink>
          </NavItem>*/}
          {/* {(this.adminType === 'admin' || isAccessTo('settingManager')) && <NavItem className="px-3">
            <NavLink href="#/settings">Settings</NavLink>
          </NavItem>} */}
        </Nav>
        <Nav className="ml-auto" navbar>
          {/* <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-bell"></i><Badge pill color="danger"></Badge></NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-list"></i></NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-location-pin"></i></NavLink>
          </NavItem> */}
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img src={'assets/img/avatars/user.png'} className="img-avatar"/>
            </DropdownToggle>
            <DropdownMenu right style={{right: 'auto'}}>
              <DropdownItem tag={Link} to={"/profile"}><i className="fa fa-user"></i>Profile</DropdownItem>
              <DropdownItem onClick={this.onLogoutHandle}><i className="fa fa-lock"></i>Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
