import Login from './Login';
import Page404 from './Page404';
import Page500 from './Page500';
import Register from './Register';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import HowToPlay from './HowToPlay';
import Legality from './Legality/Legality';
import Support from './Support';
import Privacy from './Privacy';
import Terms from './Terms';
import Disclaimer from './Disclaimer'
import InfluencerCode from './InfluencerCode';

export {
  Login, Page404, Page500, Register, AboutUs, HowToPlay, Legality, ContactUs, Support, Privacy, Terms, InfluencerCode, Disclaimer
};