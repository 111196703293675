import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import cookie from "react-cookies";
import { isEmpty, includes, intersectionBy } from "lodash";
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
import { adminNav } from "../../_nav";
// routes config
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import { apiBaseUrl } from "../../configs/configs";
import { removeCookies } from "../../helper/helper";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminType: "",
      accessRoutes: [],
      accessNavs: { items: [] },
      isLogout: false,
      accessModules: "",
    };
    this.adminId = cookie.load("adminId");
  }

  componentDidMount() {
    this.getAdminProfile();
  }

  async getAdminProfile() {
    const token = cookie.load("token");
    if (token) {
      const getUserDataApi = `${apiBaseUrl}/admin/get/${this.adminId}`;
      const response = await fetch(getUserDataApi, {
        headers: {
          token: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        const res = await response.json();
        let adminData = res.result;
        cookie.save("adminType", adminData.type || "");
        cookie.save("permissions", adminData.permissions || []);
        if (adminData.type === "admin") {
          this.setState({
            adminType: adminData.type || "",
            loading: false,
            accessRoutes: routes,
            accessNavs: adminNav,
          });
        } else {
          //let cookiemodules = cookie.load('accessModules') || '';
          cookie.save("accessModules", adminData.accessModules);
          let cookiemodules =
            (adminData.accessModules && adminData.accessModules.split(",")) ||
            [];
          let modulesObjectArray = cookiemodules.map((m) => {
            return { module: m };
          });
          let xroutes = [];
          let xnavs =
            intersectionBy(adminNav.items, modulesObjectArray, "module") || [];

          xnavs.map((nav) => {
            routes.map((route) => {
              if (route.path && route.path.indexOf(nav.url) > -1) {
                xroutes.push(route);
              } else if (
                route.path &&
                (route.path.indexOf("/password") > -1 ||
                  route.path.indexOf("/profile") > -1 ||
                  route.name === "Home")
              ) {
                // adding common routes
                xroutes.push(route);
              }
            });
          });

          this.setState({
            adminType: adminData.type || "",
            loading: false,
            accessRoutes: xroutes,
            accessNavs: { items: xnavs },
          });
        }
      } else if (response.status === 401) {
        //console.log('response ', response);
        this.setState({ isLogout: true });
      } else {
        this.setState({ loading: false });
        return new Error();
      }
    } else {
      let xroutes = [];
      let xnavs = [];

      //adding common routes
      xnavs.push({
        url: "/password",
      });

      xnavs.map((nav) => {
        routes.map((route) => {
          if (route.path && route.path.indexOf(nav.url) > -1) {
            xroutes.push(route);
          }
        });
      });

      this.setState({
        loading: false,
        accessRoutes: xroutes,
        accessNavs: { items: xnavs },
      });
      this.setState({ isLogout: true });
    }
  }

  render() {
    let routeContainer = !isEmpty(this.state.accessRoutes) ? (
      <Container fluid>
        <Switch>
          {this.state.accessRoutes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => <route.component {...props} />}
              />
            ) : null;
          })}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Container>
    ) : (
      []
    );

    const token = cookie.load("token");
    const isPasswordLink =
      this.props.location.pathname === "/password/forgot" ||
      this.props.location.pathname === "/password/reset";

    if (isEmpty(token) && isPasswordLink === true) {
      return routeContainer;
    }
    if (isEmpty(token) && isPasswordLink === false) {
      return <Redirect from="/" to="/login" />;
    }
    if (this.state.isLogout) {
      removeCookies();
      return <Redirect to="/login" />;
    }

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={this.state.accessNavs} {...this.props} />
            <AppSidebarFooter />
            {/* <AppSidebarMinimizer /> */}
          </AppSidebar>
          <main className="main">
            {!isEmpty(this.state.accessRoutes) && (
              <AppBreadcrumb appRoutes={this.state.accessRoutes} />
            )}
            {routeContainer}
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
