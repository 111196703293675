import React, {useCallback, useEffect, useState} from 'react'
import styled from "styled-components";
import {Button, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {debounce} from "lodash";

import {Link} from "react-router-dom";

const Users = ({users, onSelectUser, userSelected,}) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredUsers, setFilteredUsers] = useState(users)

  useEffect(() => {
    setFilteredUsers(users)
  }, [users])


  const handleSearch = useCallback(debounce((keyword) => {
    if (keyword) {
      const newAdmins = users.filter((user) => user.email.includes(keyword) || user.firstName.includes(keyword) || user.lastName.includes(keyword))
      setFilteredUsers(newAdmins)
    } else {
      setFilteredUsers(users)
    }

  }, 300), [])
  return <Box>
    <SearchBox>
      <div style={{display: 'flex', justifyContent: 'end', width: '100%'}}>

        {/*<SearchInput*/}
        {/*  value={searchValue}*/}
        {/*  onChange={e => {*/}
        {/*    setSearchValue(e.target.value)*/}
        {/*    handleSearch(e.target.value)*/}
        {/*  }}*/}
        {/*  placeholder="Enter email, name to search..."*/}
        {/*/>*/}
        <Link to={'/admins/add'}>
          <Button color="primary" type="submit" style={{display: 'flex', alignItems: "center", gap: 3}}>
            <svg style={{width: 20, height: 20}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v18m9-9H3"/>
            </svg>
            Add admin
          </Button>
        </Link>
        {/*<InputGroupAddon style={{background: '#080402'}} addonType="append">*/}
        {/*  <InputGroupText>*/}
        {/*    <i className="fa fa-solid fa-search" aria-hidden="true" style={{color: 'white', fontSize: 18}}>*/}
        {/*    </i>*/}
        {/*  </InputGroupText>*/}
        {/*</InputGroupAddon>*/}
      </div>

    </SearchBox>
    {filteredUsers.map(user => <User selected={userSelected?.id === user.id} key={user.id}
                                     onClick={() => onSelectUser(user)}>
      <span>{`${user.firstName} ${user.lastName}`}</span>
    </User>)}
  </Box>
}


const Box = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 5px;
`
const SearchBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`
const User = styled('div')`
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  background: ${props => props.selected ? '#6e6e6e' : '#080402'};
  border-radius: 5px;

  &:hover {
    background: #6e6e6e;
  }
`

const SearchInput = styled(Input)`
  &:focus {
    box-shadow: none;
  }
`

const Info = styled('div')`
  display: flex;
  align-items: center;
  gap: 7px;
`


export default Users
