const adminApiBaseURL = process.env.REACT_APP_ADMIN_API_BASE_URL || 'http://localhost:3002';
const coreApiBaseURL = process.env.REACT_APP_CORE_API_BASE_URL || 'http://localhost:3003';

const apiBaseUrl = `${adminApiBaseURL}/api/v1`;
const wsApiBaseUrl = `${coreApiBaseURL}/api/v1`;
const productImagePath = `${coreApiBaseURL}/products/`;
const publicSliderImagePath = `${adminApiBaseURL}/slider/image/`;
const fileUpload = `${apiBaseUrl}/common/file-upload`;


// tiny editor account
// const tinyKey = 'l21q5uynexcjb0ceu36k345l19bcs60gvcdg0pzjjfh3en4r';
const tinyKey = '73t9ese8qq4jydig6sfw4e81al4expjugbzhymdpqe05hy76';
const dateTimeFormat = 'MMMM Do, YYYY';

const defaultCurrency = 'INR';
const currency = { inr: 'INR', doller: '$' };







const maxFileSizeUpload = 2097152; // in bytes
const maxFileNameLengthUpload = 50;

const adminRoles = ['admin', 'subadmin'];

const accessModules = [

    {value: 'dashboard', label: 'Dashboard'},
    {value: 'subAdmin', label: 'Sub-Admin'},
    {value: 'users', label: 'Users'},




    {value: 'slider', label: 'Slider'},

    {value: 'settingManager', label: 'Setting Manager'},
    {value: 'notification', label: 'Notification'},
    {value: 'emailTemplates', label: 'Email Templates'},
    {value: 'howToPlay', label: 'How To Play'},
    {value: 'aboutUs', label: 'About Us'},
    {value: 'legality', label: 'Legality'},

];

const defaultAccessModules = [
    {value: 'dashboard', label: 'Dashboard'},
    {value: 'subAdmin', label: 'Sub-Admin'},
    {value: 'users', label: 'Users'},
    {value: 'slider', label: 'Slider'},
    {value: 'settingManager', label: 'Setting Manager'},
    {value: 'notification', label: 'Notification'},
  ]

module.exports = {
    apiBaseUrl,
    dateTimeFormat,
    wsApiBaseUrl,
    defaultCurrency,
    tinyKey,
    maxFileSizeUpload,
    maxFileNameLengthUpload,
    currency,
    adminRoles,
    accessModules,
    defaultAccessModules,
    publicSliderImagePath,
    productImagePath,
    fileUpload

};
