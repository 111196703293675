import React from "react";
import {Input} from "reactstrap";
import styled from 'styled-components'

const Permissions = ({rolePermissions, permissions, onSelectPermission, selectedRole, title}) => {

  return (<div>
    <Header className='d-flex align-items-center border-bottom pl-3 pr-3 py-3'>
      {title}
    </Header>
    <Box>
      {permissions.map((permission, index) =>
        <Item key={index} className='d-flex align-items-center border-bottom  pr-3 py-2'
              onClick={() => onSelectPermission(permission.value)}>
          <CheckBox onChange={() => onSelectPermission(permission.value)} type='checkbox'
                    checked={rolePermissions.includes(permission.value)}/>
          <div className="">
            <p className=" m-0" style={{fontSize: 14}}>{permission.value}</p>
            <small style={{fontSize: 12, fontStyle: 'italic'}}>{permission.title}</small>
          </div>
        </Item>
      )}
    </Box>
  </div>)
}

const Box = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
  background: #080402;
`

const Item = styled('div')`
  cursor: pointer;
  gap: 10px;
  padding-left: 40px;
  width: 47%;
  background: #080402;

  &:hover {
    background: #3a3838;
  }
`
const CheckBox = styled(Input)`
  position: relative;
`

const Header = styled('div')`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background: #969090;
`
export default Permissions
