import React, {useEffect} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Input,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "../../axios";
import {apiBaseUrl} from "../../configs/configs";
import {toast} from "react-toastify";
import styled from "styled-components";

const SettingCancelFee = () => {
  const [fee, setFee] = React.useState(undefined);
  const [minRefund, setMinRefund] = React.useState(undefined);
  const [feeError, setFeeError] = React.useState(false);
  const [minRefundFeeError, setMinRefundFeeError] = React.useState(false);

  useEffect(() => {
    axios.get(`${apiBaseUrl}/setting/cancel-fee`).then(({data}) => {
      if (data.result && !!data.result.length) {
        setFee(data.result[0].value)
        setMinRefund(data.result[0].minRefundFee)
      }
    }).catch(e => {
      toast.error(e.response.data.result.errors.length ?
        e.response.data.result.errors[0].msg :
        e.response.data.msg);
    })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!fee || !minRefund) {
      setFeeError(!fee);
      setMinRefundFeeError(!minRefund);
    } else {
      axios.post(`${apiBaseUrl}/setting/cancel-fee`, {
        fee: parseFloat(fee),
        minRefundFee: parseFloat(minRefund)
      }).then(() => {
        toast.success("Update cancel fee successfully!")
      }).catch(e => {
        toast.error(e.response.data.result.errors.length ?
          e.response.data.result.errors[0].msg :
          e.response.data.msg);
      })
    }
  }

  return <Row className="user_info">
    <Col xl={12}>
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"/>Fee Cancel setting <small className="text-muted"/>
        </CardHeader>
        <CardBody>
          <Form>
            <FormGroup className="position-relative" style={{width: '50%'}}>
              <Label style={{fontSize: 16, fontWeight: 'bold'}} for="fee">
                Fee Cancel (%)
              </Label>
              <Input precision={2} step="0.01" min="0" lang="en" style={{boxShadow: "none"}} invalid={!!feeError}
                     value={fee} onChange={(event) => {
                setFeeError(false)
                setFee(event.target.value)
              }} type="number" id="fee" name="fee" placeholder="Fee (%)" required/>
              <div style={{height: '10px'}}>
                {
                  feeError && <FallbackText>
                    Please enter fee!
                  </FallbackText>
                }
              </div>
            </FormGroup>

            <FormGroup className="position-relative" style={{width: '50%'}}>
              <Label style={{fontSize: 16, fontWeight: 'bold'}} for="fee">
                Min Refund Fee ($)
              </Label>
              <Input precision={2} step="0.01" min="0" lang="en" style={{boxShadow: "none"}}
                     invalid={!!minRefundFeeError}
                     value={minRefund} onChange={(event) => {
                setMinRefundFeeError(false)
                setMinRefund(event.target.value)
              }} type="number" id="fee" name="fee" placeholder="Min Refund fee ($)" required/>
              <div style={{height: '10px'}}>
                {
                  minRefundFeeError && <FallbackText>
                    Please enter min refund fee!
                  </FallbackText>
                }
              </div>
            </FormGroup>
            <ButtonUpdate onClick={handleSubmit} type='submit' color='danger'>Update</ButtonUpdate>
          </Form>
        </CardBody>
      </Card>
    </Col>
  </Row>
}

const ButtonUpdate = styled(Button)`
  padding: 5px 40px;
`

const Form = styled(`form`)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
`

const FallbackText = styled('span')`
  color: #d50707;
  margin-top: 1px;
  font-size: 12px;
`

export default SettingCancelFee;
