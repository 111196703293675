import React, {useEffect, useState} from "react";
import axios from "../../axios";
import {apiBaseUrl} from "../../configs/configs";
import {toast} from "react-toastify";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import Users from "./Users";
import styled from "styled-components";
import Roles from "./Roles";
import Permissions from "./Permissions";


const AdminRole = () => {
  const [{users}, setFetchAdminListState] = useState({
    users: [], fetching: false
  })

  const [{roles}, setFetchRoleList] = useState({
    roles: [], roleFetching: false
  })

  const [{permissions}, setFetchPermission] = useState({
    permissions: [], permissionFetching: false
  })

  const [userSelected, setUserSelected] = useState(null)
  const [roleSelected, setRoleSelected] = useState(null)

  const [permissionSelected, setPermissionSelected] = useState([])

  useEffect(() => {
    !!roles.length && setRoleSelected(roles.find((role) => role.id === userSelected?.role_id))
  }, [userSelected, roles])

  const getPermissions = () => {
    axios.get(`${apiBaseUrl}/permissions`).then(({data}) => {
      setFetchPermission({
        permissions: data.permissions
      })
    }).catch(e => {
      toast.error(e.response.data.result.errors.length ? e.response.data.result.errors[0].msg : e.response.data.msg);
      setFetchAdminListState({
        fetching: false
      })
    })
  }

  useEffect(() => {
    getPermissions()
  }, [])


  const getAdminList = () => {
    axios.get(`${apiBaseUrl}/roles/admins`).then(({data}) => {
      setFetchAdminListState({
        users: data.result, fetching: false
      })
      !userSelected && setUserSelected(data.result[0])
      if (!permissionSelected.length) {
        console.log(1);
        setPermissionSelected(data.result[0].permissions || [])
      }
    }).catch(e => {
      toast.error(e.response.data.result.errors.length ? e.response.data.result.errors[0].msg : e.response.data.msg);
      setFetchAdminListState({
        fetching: false
      })
    })
  }

  useEffect(() => {
    axios.get(`${apiBaseUrl}/roles`).then(({data: {result: {data}}}) => {

      if (!data.length) {
        toast.warn('The system does not have a role, please add a role before proceeding', {
          autoClose: 7000
        })
      }

      setFetchRoleList({
        roles: data, roleFetching: false
      })

    }).catch(e => {
      toast.error(e.response.data.result.errors.length ? e.response.data.result.errors[0].msg : e.response.data.msg);
      setFetchRoleList({
        fetching: false
      })
    })

  }, [])

  useEffect(() => {
    setFetchAdminListState({
      users: [], fetching: true
    })
    getAdminList()
  }, [])

  const handleChangeUserSelected = (user) => {
    setUserSelected(user)
    setPermissionSelected(user.permissions || [])
    const currentRole = roles.find((role) => role.id === user.role_id)
    setRoleSelected(currentRole)
  }

  const handleChangeSelectedRole = (selectedId) => {
    const currentRole = roles.find((role) => role.id === selectedId)
    setRoleSelected(currentRole)
    setPermissionSelected(currentRole?.permissions || [])
  }

  const assignPermissionToAdmin = (adminId, roleId, permissions, sucessMessage) => {
    axios.post(`${apiBaseUrl}/assign-roles`, {
      roleId, permissions: permissions, adminId
    }).then(() => {
      toast.success(sucessMessage)
      getAdminList()
    }).catch(e => {
      toast.error(e.response.data.result.errors.length ? e.response.data.result.errors[0].msg : e.response.data.msg);
    })
  }


  const handleAssign = (permissions) => {
    assignPermissionToAdmin(userSelected.id, roleSelected.id, permissions, 'Update right success')

  }

  const handleUpdateRole = (role) => {
    assignPermissionToAdmin(userSelected.id, role.id, permissionSelected, 'Assign role to admin success')
  }


  return <Row className="user_info">
    <Col xl={12}>
      <Card>
        <CardHeader>
          <i className="fa fa-align-justify"/> Admin Role Manager <small className="text-muted"/>
        </CardHeader>
        <Body>
          <UsersBox>
            <Users
              userSelected={userSelected}
              users={users}
              onSelectUser={handleChangeUserSelected}
            />
          </UsersBox>
          <RoleBox>
            {userSelected && <UserInformation>
              <Avatar>
                {`${userSelected.firstName.slice(0, 1)}${userSelected.lastName.slice(0, 1)}`}
              </Avatar>
              <NameBox>
                <Name>{`${userSelected.firstName} ${userSelected.lastName}`}</Name>
                <EmailPhoneBox>
                  <InfoItem>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none"
                         viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                    </svg>
                    <InfoText>{userSelected.email || 'No email'}</InfoText>
                  </InfoItem>
                  <InfoItem>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none"
                         viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round"
                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                    </svg>
                  </InfoItem>
                  <InfoText>{userSelected.mobile || 'No phone'}</InfoText>
                </EmailPhoneBox>
              </NameBox>
            </UserInformation>}
            <Roles
              onUpdateRole={handleUpdateRole}
              userSelected={userSelected}
              selectedRole={roleSelected}
              roles={roles}
              onChangeRoleName={handleChangeSelectedRole}
            />
            <Permissions
              seRolePermissions={setPermissionSelected}
              onAssignPermissionToAdmin={handleAssign}
              permissions={permissions}
              rolePermissions={permissionSelected}
            />
          </RoleBox>
        </Body>
      </Card>
    </Col>
  </Row>
}

const Body = styled(CardBody)`
  display: flex;
  gap: 30px;
  background: #EDEDED;
`
const UsersBox = styled('div')`
  flex-basis: 400px;
  background: #080402;
  border: 0.5px solid #b6b5b5;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`

const UserInformation = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #b6b5b5;
  width: 100%;
  padding: 15px 10px;
`
const NameBox = styled('div')`
  display: flex;
  flex-direction: column;

`

const RoleBox = styled('div')`
  width: 55%;
  border: 0.5px solid #b6b5b5;
  border-radius: 5px;
  background: #080402;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

`
const Name = styled('span')`
  font-size: 16px;
  font-weight: 500;
  color: white;
`

const EmailPhoneBox = styled('div')`
  display: flex;
  gap: 20px;
  align-items: center;
`

const InfoItem = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #00aced;
`

const Avatar = styled('div')`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #00bf8f;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
`

const InfoText = styled('span')`
  font-size: 14px;
  color: white;
  font-weight: 500;
`

export default AdminRole
