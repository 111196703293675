import moment from 'moment';
import { isEmpty } from 'lodash';
import React from 'react';
import cookie from 'react-cookies';

import { defaultCurrency, maxFileNameLengthUpload, maxFileSizeUpload, accessModules } from '../configs/configs';

const getLocalDateTime = (date = '', format = 'MMMM Do YYYY, h:mm a') => {
  return moment.utc(date).local().format(format);
}

const decodeData = (encodedData) => {
  try {
    return window.atob(encodedData);
  }
  catch (err) {
    return encodedData;
  }

}

const getContestDateTime = (dateTime = '', format = 'MMMM Do YYYY, h:mm a') => {

  var time = moment.duration("00:30:00");
  var date = moment(dateTime);
  date.subtract(time);

  return date.format(format)


  // return moment(date).local().format(format);
}


const toUTC = (dateTime = '') => moment(dateTime).utc().format();

const toLocalDateTime = (dateTimeGMT, format = 'YYYY-MM-DD') => moment(dateTimeGMT).local().format(format);

const getCurrentDateTime = (format = 'YYYY-MM-DD') => moment().format(format);

const joinDateTime = (dateGMT = '', timeGMT = '') => {
  // provide in gmt format and it will return the join in gmt format
  if (!isEmpty(dateGMT) && !isEmpty(timeGMT)) {

    return moment(`${dateGMT} ${timeGMT}`).parseZone().format();

  } else {

    return '';

  }

};

const loading = function () {
  if (this.state.loading) {
    return (
      <div><div className={"text-center"} colSpan="8"><i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <span className="sr-only">Loading...</span></div></div>

    )
  }
}
const inlineLoading = function () {
  if (this.state.inlineLoading) {
    return <i className="fa fa-spinner fa-spin"></i>
  }
}

const getPriceByCurrency = (amount) => {
  if (defaultCurrency == 'INR') {
    return amount + ' INR';
  } else {
    return '$ ' + amount;
  }
}

const fileValidate = function () {

  if (this.fileInput && !isEmpty(this.fileInput.current)) {

    let alert = {

      type: 'danger',
      headLine: 'Oops !!',
      time: (new Date()).getTime()

    };
    const currentFile = (this.fileInput.current && this.fileInput.current.files[0]) || {};

    let fileName = !isEmpty(currentFile.name) && currentFile.name.toLowerCase();

    if (!isEmpty(fileName) && !fileName.match(/\.(jpg|jpeg|png|gif)$/)) {

      alert.message = 'Only Image files are allowed.';
      this.setState({ alert });
      return false;

    } else if (!isEmpty(fileName) && fileName.length > maxFileNameLengthUpload) {
      alert.message = 'Image name too long.';
      this.setState({ alert });
      return false;

    } else if (currentFile.size && (currentFile.size > maxFileSizeUpload)) {
      alert.message = 'Image size can\'t be larger than 2mb.';
      this.setState({ alert });
      return false;

    } else {

      return true;

    }

  }

}
const getOnUpdateSuccessIcon = function () {

  if (this.state.updateSuccess === true) {

    return <i className="fa fa-check-circle" aria-hidden="true"></i>

  }


}

const removeCookies = (name = []) => {

  try {
    if (isEmpty(name)) {

      cookie.remove('adminId');
      cookie.remove('adminType');
      cookie.remove('token');

    } else {

      name.forEach((cookie) => {
        cookie.remove(cookie);
      });

    }
  } catch (err) {

  }

};

const getAccessModules = (modules = '') => {

  if (isEmpty(modules)) {

    return [];

  }

  let modulesList = modules.split(',');

  let accessingModules = [];

  modulesList.map((moduleValue = '') => {

    accessingModules.push(accessModules.find((modul) => modul.value === moduleValue));

  });

  return accessingModules || [];

};

const isAccessTo = (moduleName = '') => {

  const modules = cookie.load('accessModules');
  if (modules.indexOf(moduleName) > -1) {
    return true;
  } else {
    return false;
  }

};

function generateUniqueById() {
  // let time = getCurretTimeStamp();
  // let uniqueId = userId + time.toString();
  // uniqueId = uniqueId.slice(0, 6);
  return Math.floor(100000 + Math.random() * 900000);
}
const toCapitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export {
  getLocalDateTime,
  toUTC,
  toLocalDateTime,
  getCurrentDateTime,
  joinDateTime,
  getContestDateTime,
  loading,
  inlineLoading,
  getPriceByCurrency,
  fileValidate,
  getOnUpdateSuccessIcon,
  removeCookies,
  getAccessModules,
  isAccessTo,
  decodeData,
  generateUniqueById,
  toCapitalize
};