import React, {useCallback, useEffect, useState} from 'react';
import {apiBaseUrl} from "../../configs/configs";
import axios from "../../axios";
import Loading from "../Loading";
import {Card, CardBody, CardHeader, Col, Row,} from "reactstrap";
import Permissions from "./Permissions";
import Stack from "../Stack";
import styled from 'styled-components'
import CreatingRole from "./CreatingRole";
import Role from "./Role";
import {toast} from 'react-toastify';

const Roles = () => {
  const [{
    permissions, loading
  }, setPermissionState] = useState({
    permissions: [], loading: false, error: null
  })

  const [isEditing, setIsEditing] = useState(false)

  const [, setCreateState] = useState({
    createdRole: null, creating: false
  })

  const [, setEditState] = useState({
    editedRole: null, editing: false
  })

  const [{
    roles
  }, setRoleState] = useState({
    roles: [], pagination: null
  })
  const [selectedRole, setSelectedRole] = useState(null)
  const [addMode, setAddMode] = useState(false)
  const [addPermissions, setAddPermissions] = useState([])

  useEffect(() => {
    setPermissionState({
      permissions: [], loading: true, error: null
    })
    axios.get(`${apiBaseUrl}/permissions`).then(({data}) => {
      setPermissionState({
        permissions: data.permissions, loading: false, error: null
      })
    })
  }, [])

  const fetchingRoles = useCallback((isAddToStore = false) => {
    axios.get(`${apiBaseUrl}/roles`).then(({data: {result: {data, pagination}}}) => {
      setRoleState({
        roles: data, pagination
      })
    })
  }, [apiBaseUrl])

  useEffect(() => {
    fetchingRoles(true)
  }, [fetchingRoles])

  const handleChangePermission = (permission) => {
    if (selectedRole) {
      const testingPermission = selectedRole.permissions.find((testing) => testing === permission)
      if (testingPermission) {
        const newPermissions = selectedRole.permissions.filter((testing) => testing !== permission)
        return {
          ...selectedRole, permissions: newPermissions
        }
      } else {
        return {
          ...selectedRole, permissions: [...selectedRole.permissions, permission]
        }
      }
    }
  }

  const handleAddRole = (name) => {
    setCreateState({
      createdRole: null, creating: true
    })

    axios.post(`${apiBaseUrl}/roles`, {
      name, permissions: addPermissions
    }).then(() => {
      setCreateState({
        createdRole: null, creating: false
      })
      setAddPermissions([])
      toast("Created role!");
      fetchingRoles()
    }).catch(e => {
      toast.error(e.response.data.result.errors.length ?
        e.response.data.result.errors[0].msg :
        e.response.data.msg);
      setCreateState({
        createdRole: null, creating: false
      })
    })
  }

  console.log(selectedRole, '-----');

  const handleSelectRole = (role) => {
    setAddMode(false)
    setSelectedRole(role)
  }
  const handleEditRole = (role) => {
    setEditState({
      editedRole: null, editing: true
    })
    axios.put(`${apiBaseUrl}/roles/${role.id}`, role).then(({data}) => {
      setEditState({
        editedRole: null, editing: true
      })
      toast("Updated role!");

      fetchingRoles()
    }).catch(e => {
      setEditState({
        editedRole: null, editing: true
      })
      toast.error(e.response.data.result.errors.length ?
        e.response.data.result.errors[0].msg :
        e.response.data.msg);
    })
  }

  const handleDeleteRole = (id) => {
    axios.delete(`${apiBaseUrl}/roles/${id}`).then(() => {
      toast("Deleted role!");
      fetchingRoles()
    }).catch(e => {
      toast.error(e.response.data.msg);
    })
  }

  const handleChangeAddPermission = (permission) => {
    const testingPermission = addPermissions.find((testing) => testing === permission)
    if (testingPermission) {
      setAddPermissions(addPermissions.filter((testing) => testing !== permission))
    } else {
      setAddPermissions([...addPermissions, permission])
    }
  }

  console.log(isEditing);

  return <div className="animated fadeIn">
    <Row className="user_info">
      <Col xl={12}>
        <Card>
          <CardHeader>
            <i className="fa fa-align-justify"/> Role Manager <small className="text-muted"/>
          </CardHeader>
          <CardBody>
            <CreatingRole onFocus={() => {
              setAddMode(true)
              setSelectedRole(null)
            }} onSubmit={handleAddRole}/>
            <Stack spacing={50} justifyContent='start'>
              <RoleBox className="border">
                {!!roles.length ? <Stack direction='column' className='d-flex'>
                  <RoleHeader className='py-3 px-3 border-bottom w-100'>
                    <span>Roles</span>
                  </RoleHeader>
                  {roles.map((role) => {
                    return <Role
                      setIsEditMode={(value) => {
                        setIsEditing(value)
                      }}
                      onSelectRole={handleSelectRole}
                      role={role}
                      active={role.id === selectedRole?.id}
                      key={role.id}
                      onEditRole={(id, name) => handleEditRole({
                        ...selectedRole, name
                      })}
                      onDeleteRole={handleDeleteRole}
                    />
                  })}
                </Stack> : <NoRow>
                  <span>No roles</span>
                </NoRow>}
              </RoleBox>
              <div className="border w-50" style={{background: "whitesmoke",}}>
                {
                  !addMode ? <> {!!permissions.length ? selectedRole && !isEditing ? <Permissions
                          title={<> Permissions of role <u style={{
                            margin: 0,
                            padding: '0 5px',
                            color: '#1100E4',
                            fontWeight: 'bold'
                          }}>{selectedRole?.name}</u></>}
                          selectedRole={selectedRole}
                          onSelectPermission={(permission) => {
                            const newRole = handleChangePermission(permission)
                            setSelectedRole(newRole)
                            handleEditRole(newRole)
                          }}
                          permissions={permissions}
                          rolePermissions={selectedRole?.permissions || []}/> :
                        <PermissionMessage>Please select role</PermissionMessage> :
                      <PermissionMessage>No permissions</PermissionMessage>}</> :
                    <>
                      {!!permissions.length && <Permissions
                        onSelectPermission={(permission) => {
                          handleChangeAddPermission(permission)
                        }}
                        title={<> Permission for new role </>}
                        permissions={permissions}
                        rolePermissions={addPermissions}/>}
                    </>
                }
              </div>
            </Stack>
            {loading && <Loading/>}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
}

const NoRow = styled('div')`
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
  flex-direction: column;

  & > span {
    font-weight: bold;
    font-size: 20px;
  }
`

const RoleBox = styled('div')`
  flex-basis: 400px;
`
const RoleHeader = styled('div')`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background: #969090;
`

const PermissionMessage = styled('div')`
  width: 100%;
  height: 200px;
  background: #080402;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: initial;
  font-size: 20px;
`

export default Roles
