import React from "react";

const Stack = ({direction = 'row', children, spacing, justifyContent = 'start', alignItems = "start"}) => {
  return <div style={{
    display: 'flex',
    flexDirection: direction,
    gap: `${spacing}px`,
    justifyContent,
    alignItems
  }}>{children}</div>
}

export default Stack
