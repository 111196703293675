const {routerNeedPermissions, routeWithPermissions, permissions} = require("./routerWithPermissions");
const adminNav = {
  items: [
    {
      name: 'Dashboard',
      module: 'dashboard',
      url: '/dashboard',
      icon: 'icon-chart',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Admin Manager',
      module: 'admin',
      url: '/profile',
      icon: 'icon-people',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Contestant Manager',
      module: 'contestant',
      url: '/users',
      icon: 'icon-people',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Uploaded Videos',
      module: 'videos',
      url: '/videos',
      icon: 'icon-playlist',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Abusive Videos',
      module: 'videos',
      url: '/abusive-video',
      icon: 'icon-pie-chart',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Reports Manager',
      module: 'reports',
      url: '#',
      icon: 'icon-grid',
      badge: {
        variant: 'info',
      },
      children: [
        {
          name: 'Contestants Report',
          url: '/reports/contestantreport',
          module: 'report',
          icon: 'fa fa-file-text-o',
          badge: {
            variant: 'info',
          },
        },

        {
          name: 'Video Report',
          url: '/reports/videoreport',
          module: 'report',
          icon: 'fa fa-file-text-o',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Winners Report',
          url: '/reports/winnerreport',
          module: 'report',
          icon: 'fa fa-file-text-o',
          badge: {
            variant: 'info',
          },
        },
        // {
        //   name: 'Total Subscriptions old',
        //   url: '/reports/subscriptionreport',
        //   module: 'report',
        //   icon: 'fa fa-file-text-o',
        //   badge: {
        //     variant: 'info',
        //   },
        // },
        {
          name: 'Subscriptions Report',
          url: '/reports/subscriptionreport',
          module: 'report',
          icon: 'fa fa-file-text-o',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Packages Report',
          url: '/reports/packagereport',
          module: 'report',
          icon: 'fa fa-file-text-o',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Earning Report',
          url: '/reports/earningreport',
          module: 'report',
          icon: 'fa fa-file-text-o',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Category Winners Report',
          url: '/reports/cat-winner',
          module: 'report',
          icon: 'fa fa-file-text-o',
          badge: {
            variant: 'info',
          },
        },

      ].filter((route) => {

        if (!routerNeedPermissions.includes(route.url)) {
          return true
        } else {
          const currentRouterWithPermission = routeWithPermissions.find(testingRoute => testingRoute.path === route.url)
          return !!currentRouterWithPermission?.permissions.some((testingPermission) => permissions.includes(testingPermission));
        }
      })
    },

    // {
    //   name: 'Package Manager',
    //   module: 'package',
    //   url: '/package',
    //   icon: 'fa fa-address-card',
    //   badge: {
    //     variant: 'info',
    //   },
    // },
    {
      name: 'Subscription Manager',
      module: 'package',
      url: '/package',
      icon: 'fa fa-address-card',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Leaderboard Manager',
      module: 'leaderboard',
      url: '/leader-board',
      icon: 'fa fa-address-card',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Notification Manager',
      module: 'notification',
      url: '/usernotifications/send',
      icon: 'fa fa-bell-o',
      badge: {
        variant: 'info',
      },
    },

    /*{
      name: 'Content Manager',
      module: 'notification',
      url: '/usernotifications',
      icon: 'icon-grid',
      badge: {
        variant: 'info',
      },
      children: [
        {
          name: 'Legality',
          url: '/legality',
          module: 'legality',
          icon: 'fa fa-file-text-o',
          badge: {
            variant: 'info',
          },
        },

        {
          name: 'About Us',
          url: '/aboutus',
          module: 'aboutUs',
          icon: 'fa fa-institution',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Terms & Conditions',
          url: '/condition',
          module: 'condition',
          icon: 'fa fa-institution',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Privacy Policy',
          url: '/privacy',
          module: 'privacy',
          icon: 'fa fa-institution',
          badge: {
            variant: 'info',
          },
        },

      ]
    },*/

    {
      name: 'Content Manager',
      url: '/content',
      module: 'Content',
      icon: 'icon-grid',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Email Templates',
      url: '/templates',
      module: 'emailTemplates',
      icon: 'fa fa-envelope-o',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Contact Us',
      url: '/contactus',
      module: 'emailTemplates',
      icon: 'fa fa-database',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Setting Manager',
      url: '/settings',
      module: 'settings',
      icon: 'icon-wrench',
      badge: {
        variant: 'info',
      },
    },

    // {
    //   name: 'E-Commerce',
    //   url: '',
    //   icon: 'fa fa-shopping-cart',
    // },

    {
      name: 'Category Manager',
      module: 'category',
      url: '#',
      icon: 'fa fa-th-list',
      badge: {
        variant: 'info',
      },
      children: [
        {
          name: 'Category',
          url: '/category',
          module: 'category',
          icon: 'icon-grid',
          badge: {
            variant: 'info',
          },
        },

        {
          name: 'Sub-Category',
          url: '/sub-category',
          module: 'category',
          icon: 'icon-grid',
          badge: {
            variant: 'info',
          },
        },
      ].filter((route) => {

        if (!routerNeedPermissions.includes(route.url)) {
          return true
        } else {
          const currentRouterWithPermission = routeWithPermissions.find(testingRoute => testingRoute.path === route.url)
          return !!currentRouterWithPermission?.permissions.some((testingPermission) => permissions.includes(testingPermission));
        }
      })
    },
    {
      name: 'Products Manager',
      url: '/product',
      module: "products",
      icon: 'fa fa-product-hunt',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Service Charges',
      url: '/service-charge',
      module: "products",
      icon: 'fa fa-money',
      badge: {
        variant: 'info',
      },
    },
    /*{
      name: 'Inventory Manager',
      url: '#',
      module: 'inventory',
      icon: 'fa fa-stack-exchange',
      badge: {
        variant: 'info',
      },
    },*/
    {
      name: 'Promocode Manager',
      url: '/promocode',
      module: 'promocode',
      icon: 'fa fa-gift',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Orders Manager',
      url: '/orders',
      module: 'orders',
      icon: 'fa fa-shopping-cart',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Influencer Manager',
      url: '/influencer-manager',
      module: 'referrals',
      icon: 'icon-people',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Earning Manager',
      url: '/earning',
      module: 'earning',
      icon: 'fa fa-usd',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Review Rating Manager',
      url: '/review-rating',
      module: 'reviewRating',
      icon: 'fa fa-star-half-o',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Role Manager',
      url: '/roles',
      module: 'roleManager',
      icon: 'fa fa-solid fa-lock',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Admin Role Manager',
      url: '/admin-roles',
      module: 'adminRoleManager',
      icon: 'fa fa-brands fa-gg',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Cancel Fee Setting',
      url: '/setting-cancel-fee',
      module: 'adminCancelFeeSetting',
      icon: 'fa fa-solid fa-gear',
      badge: {
        variant: 'info',
      },
    },
  ].filter((route) => {
    if (!routerNeedPermissions.includes(route.url)) {
      return true
    } else {
      const currentRouterWithPermission = routeWithPermissions.find(testingRoute => testingRoute.path === route.url)
      if (route.url === '/influencer-manager') {
        console.log("currentRouterWithPermission; ", currentRouterWithPermission, permissions);
  
      }
      return !!currentRouterWithPermission?.permissions.some((testingPermission) => permissions.includes(testingPermission));
    }
  })
};

const subAdminNav = {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-chart',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Admin add',
      url: '/users/add',
      icon: 'icon-speedometer',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Users',
      url: '/users',
      icon: 'icon-people',
      badge: {
        variant: 'info',
      },
    },

    {
      name: 'Slider',
      url: '/slider',
      icon: 'icon-screen-desktop',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Setting Manager',
      url: '/settings',
      icon: 'icon-wrench',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Email Templates',
      url: '/templates',
      icon: 'fa fa-envelope-o',
      badge: {
        variant: 'info',
      },
    },
  ].filter((route) => {

    if (!routerNeedPermissions.includes(route.url)) {
      return true
    } else {
      const currentRouterWithPermission = routeWithPermissions.find(testingRoute => testingRoute.path === route.url)
      return !!currentRouterWithPermission?.permissions.some((testingPermission) => permissions.includes(testingPermission));
    }
  })
};

module.exports = {
  adminNav,
  subAdminNav

};
