import React, { Component } from 'react';
import { Button, Badge, Card, CardBody, Input, Col, Row, Table, DropdownToggle, Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import { isEmpty, remove as __remove, some } from 'lodash';
import { dateTimeFormat } from '../../configs/configs';
import { getLocalDateTime } from '../../helper/helper';
import Alert from '../AlertMessage/Alert';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
class SubscriptionListTable extends Component {
  constructor(props) {
    
    super(props);
    this.state = {
      subscriptionList: this.props.subscriptionList || [],
      isRowDeleted: false,
      alertClass: '',
      alertMessage: '',
      alert: {}
    };
  };

  static getDerivedStateFromProps(props, state) {
    if (state.subscriptionList !== props.subscriptionList && state.isRowDeleted === false) {
      return {
        subscriptionList: props.subscriptionList
      };
    } else {
      return null;
    }
  }

  render() {
    let subscriptionList = this.state.subscriptionList;

    return (
      <div>
        {this.state.isRowDeleted && <div><hr /><Alert color={this.state.alertClass}>
          {this.state.alertMessage}
        </Alert></div>}
        <Table responsive hover>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Package ID</th>
              <th scope="col">Is Expired</th>
              <th scope="col">Registered On</th>
              
            </tr>
          </thead>
          <tbody>
            {!isEmpty(subscriptionList) ? subscriptionList.map((item, index) =>
            // console.log("moment(item.expired_at)------>",moment(item.expired_at).format("MM/DD/YYYY"))
              <tr key={item.id.toString()}>
                <td>{item.id}</td>
                <td>{item.product_id}</td>
                <td>{item.expired_at ? moment(item.expired_at).format("MM/DD/YYYY") :null}</td>
                <td>{!isEmpty(item.purchase_at) ? moment(item.purchase_at).format("MM/DD/YYYY") : null}</td>
              </tr> 
            ) : null}
          </tbody>
        </Table>
        {!isEmpty(this.state.alert.message) && <Alert data={this.state.alert} />}
      </div>
    );
  };
};

export default SubscriptionListTable;