import React, { Component } from 'react';
import { Collapse, Button, Card, CardBody, Col, Row } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

class DisclaimerView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: this.props.aboutUsData,
        };


    }

    render() {
        return (

            <Card>
                <CardBody>
                    {ReactHtmlParser(this.state.content)}
                </CardBody>
            </Card>

        );
    }
}

export default DisclaimerView;
