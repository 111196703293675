import React, {useState} from "react";
import styled from "styled-components";
import {Button, Input} from "reactstrap";

const CreatingRole = ({onSubmit, onFocus}) => {
  const [name, setName] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    if (name) {
      onSubmit(name)
      setName('')
    }
  }

  return <Box>
    <NameInput
      onFocus={onFocus}
      type='text'
      value={name}
      onChange={e => setName(e.target.value)}
      placeholder='Typing name of new role...'
    />
    <Button color='primary' type='submit' onClick={handleSubmit}>
      <ButtonContent>
        <svg xmlns="http://www.w3.org/2000/svg" style={{width: 20, height: 20}} fill="none" viewBox="0 0 24 24"
             stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4"/>
        </svg>
        <span>New Role</span>
        <div/>
      </ButtonContent>
    </Button>
  </Box>
}

const Box = styled('form')`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 5px 0 20px 0;
`

const NameInput = styled(Input)`
  width: 50%;
  border-width: 2px;
`
const ButtonContent = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: space-between;
`

export default CreatingRole
