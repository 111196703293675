import axiosLib from 'axios'
import {apiBaseUrl} from "./configs/configs";
import cookie from "react-cookies";
import {Redirect} from "react-router-dom";
import React from "react";

const axios = axiosLib.create({
  baseURL: apiBaseUrl
})

axios.interceptors.response.use((res) => {
  return {
    ...res,
  }
}, error => {
  if (error.response.status === 401) {
    return <Redirect to={'/login'}/>
  }
  throw error
})

axios.interceptors.request.use((config) => {
  config.headers.token = cookie.load('token')
  return config
})

export default axios
